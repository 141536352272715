import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VehicleSelectorNavigation = class VehicleSelectorNavigation extends Vue {
    isNavigateBackDisabled;
    navigateBackLabel;
    onNavigateBack(event) {
        this.$emit('onNavigateBack', event);
    }
    onClose(event) {
        this.$emit('onClose', event);
    }
};
__decorate([
    Prop({ required: true })
], VehicleSelectorNavigation.prototype, "isNavigateBackDisabled", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorNavigation.prototype, "navigateBackLabel", void 0);
VehicleSelectorNavigation = __decorate([
    Component({ name: 'VehicleSelectorNavigation' })
], VehicleSelectorNavigation);
export default VehicleSelectorNavigation;
