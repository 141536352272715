import { getPriceCharges, mapTaxDetails } from '@/store/modules/vmds/utils';
import { EngineConcept, ProductGroup, } from '@/store/modules/vmds/types';
import { STEPS } from '@/store/modules/vehicleSelector/constants';
import { ENVIRONMENTAL_BONUS_MANUFACTURER } from '@/utils';
import { APP_BRAND } from '@/utils';
export const singleClassSelectorFirstStep = STEPS.THREE_CHOOSE_MOTORIZATION;
export function getMotorizationPayload(motorization, applicationBrand) {
    const { baumuster, body, brand, changeYear, combustionEnginePower, condition, consumption, currency, division, electricEnginePower, emissions, engine, engineConcept, environmentalDiscount, listPrice, modelSeriesFacelift, modelYear, name, nst, taxes, } = motorization;
    const productGroup = motorization?.productGroup;
    let vmdsDivision;
    if (productGroup === 'PASSENGER_CAR') {
        vmdsDivision = 'pc';
    }
    else if (productGroup === 'VAN') {
        vmdsDivision = 'van';
    }
    const mapTaxes = mapTaxDetails(taxes);
    const charges = getPriceCharges(mapTaxes);
    const isVans = applicationBrand === APP_BRAND.VANS;
    const defaultDivision = isVans ? 'van' : 'pc';
    return {
        vehicleConfiguration: {
            baumuster,
            brand: brand ? brand.toLowerCase() : 'mercedes-benz',
            changeYear: changeYear || '',
            division: vmdsDivision ?? division ?? defaultDivision,
            facelift: modelSeriesFacelift,
            modelYear: modelYear || '',
            nst: nst || null,
        },
        condition: {
            condition: condition || 'new',
        },
        technicalData: getTechnicalData(combustionEnginePower, electricEnginePower, emissions, engineConcept, body, engine, consumption),
        name,
        prices: [
            {
                id: 'grossListPrice',
                currency,
                rawValue: listPrice,
                charges,
            },
            {
                id: 'baseListPrice',
                currency,
                rawValue: listPrice,
                charges,
            },
        ],
        additionalValues: getAdditionalValues(environmentalDiscount),
    };
}
function getCo2WLTPEmissionForEngineType(emissions, engineConcept) {
    switch (engineConcept) {
        case EngineConcept.COMBUSTION:
        case EngineConcept.MILD_HYBRID:
        case EngineConcept.ELECTRIC:
            return emissions?.wltp?.total?.primaryEngine?.individual?.toString();
        case EngineConcept.PLUGIN_HYBRID:
            return emissions?.wltp?.weighted?.primaryEngine?.individual?.toString();
        default:
            return null;
    }
}
export function vehicleDivision(productGroup) {
    switch (productGroup) {
        case ProductGroup.VAN:
            return 'van';
        case ProductGroup.PASSENGER_CAR:
            return 'car';
        default:
            return '';
    }
}
function getElectricConsumptionWltp(wltp) {
    const hasSecondaryEngine = wltp?.chargeDepleteWeighted?.secondaryEngine?.individual?.toString();
    const hasOnlyPrimaryEngine = wltp?.total?.primaryEngine?.max?.toString();
    return hasSecondaryEngine || hasOnlyPrimaryEngine;
}
function getTechnicalData(combustionEnginePower, electricEnginePower, emissions, engineConcept, body, engine, consumption) {
    return [
        {
            id: 'combustionPowerKw',
            value: combustionEnginePower?.toString() || null,
        },
        {
            id: 'electricPowerKw',
            value: electricEnginePower?.toString() || null,
        },
        {
            id: 'co2EmissionWltp',
            value: getCo2WLTPEmissionForEngineType(emissions, engineConcept),
        },
        {
            id: 'maxWeight',
            value: body?.weights?.weightMax?.value?.toString(),
        },
        {
            id: 'seats',
            value: body?.seats?.toString(),
        },
        {
            id: 'electricRangeWltp',
            value: engine?.electricEngine?.wltpRange?.total?.all?.individual?.toString(),
        },
        {
            id: 'electricConsumptionWltp',
            value: getElectricConsumptionWltp(consumption?.wltp),
        },
    ].filter(({ value }) => value);
}
function getAdditionalValues(environmentalDiscount) {
    if (!environmentalDiscount) {
        return;
    }
    return [
        {
            rawAmount: environmentalDiscount,
            type: ENVIRONMENTAL_BONUS_MANUFACTURER,
        },
    ];
}
export function formatFuelType(fuelType = '') {
    return fuelType.replace('_', ' ').toLowerCase();
}
export function getMainFuelType(fuelTypes) {
    return fuelTypes.length ? fuelTypes[0] : '';
}
export function capitalize(value) {
    return typeof value === 'string' ? `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}` : '';
}
export function getDefaultImage(vehicle) {
    return vehicle.stagedImgURL;
}
export const getDefaultMotorization = (vehicles, typeId) => {
    return vehicles.find((item) => item.typeId === typeId) || vehicles[0];
};
export const getDefaultBodytype = (vehicleClass, bodytypeId) => {
    const bodytypes = vehicleClass.bodies;
    const defaultBodytype = bodytypes.find((item) => {
        return item.id === bodytypeId;
    });
    return defaultBodytype || bodytypes[0];
};
