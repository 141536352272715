import { __decorate } from "tslib";
import { MODULES } from '@/store/modules';
import { mapActions, mapGetters, mapState } from 'vuex';
import { STEPS } from '@/store/modules/vehicleSelector/constants';
import { Vue, Component } from 'vue-property-decorator';
import { formatFuelType, getDefaultImage } from '@/store/modules/vehicleSelector/utils';
import { inputFormatting, CUSTOMER_TYPE, getValidPriceTag } from '@/utils';
import { ScrollLockService } from '@workbench/core';
import { FuelType, Transmission } from '@/store/modules/vmds/types';
import { SELECTOR_TYPES, tracking } from '@/utils/TrackingManager';
import VehicleSelectorBodyType from '@/components/vehicle-selector-body-type/vehicle-selector-body-type.vue';
import VehicleSelectorModel from '@/components/vehicle-selector-model/vehicle-selector-model.vue';
import VehicleSelectorMotorization from '@/components/vehicle-selector-motorization/vehicle-selector-motorization.vue';
import VehicleSelectorNavigation from '@/components/vehicle-selector-navigation/vehicle-selector-navigation.vue';
import VehicleSelectorSlide from '@/components/vehicle-selector-slide/vehicle-selector-slide.vue';
import VehicleSelectorSlides from '@/components/vehicle-selector-slides/vehicle-selector-slides.vue';
import VehicleSelectorTitleStep from '@/components/vehicle-selector-title-step/vehicle-selector-title-step.vue';
let VehicleSelectorBody = class VehicleSelectorBody extends Vue {
    STEPS = STEPS;
    formatting;
    translate;
    getDefaultImage = getDefaultImage;
    slidingDirection = 'left';
    outputsConfig;
    back;
    navigate;
    selectClass;
    selectBodyType;
    selectVehicle;
    toggleVehicleSelector;
    getBodyTypesForClass;
    getVehiclesForBodyType;
    defaultCustomerType;
    getImageForBodyType;
    selectedClass;
    selectedBodytype;
    selectedVehicle;
    scrollLockService = new ScrollLockService();
    isFullscreenDisabled;
    getPriceDescription(vehicle) {
        if (!this.defaultCustomerType) {
            return '';
        }
        const isBusiness = this.defaultCustomerType === CUSTOMER_TYPE.BUSINESS;
        const priceTag = getValidPriceTag(vehicle, isBusiness);
        return priceTag === 'netPrice' ? this.translate('vatExcluded') : this.translate('vatIncluded');
    }
    /**
     * @deprecated To be handled in the vehicle transformation layer
     * after VMDS refactoring
     */
    getPower(maxEngineData) {
        return maxEngineData && maxEngineData.powerInKw ? maxEngineData.powerInKw : null;
    }
    /**
     * @deprecated To be handled in the vehicle transformation layer
     * after VMDS refactoring
     */
    getPrice(vehicle) {
        const isBusiness = this.defaultCustomerType === CUSTOMER_TYPE.BUSINESS;
        const priceTag = getValidPriceTag(vehicle, isBusiness);
        return inputFormatting(vehicle[priceTag], this.formatting);
    }
    getFuelType(fuelTypes) {
        const mainFuelType = fuelTypes[0];
        switch (mainFuelType) {
            case FuelType.DIESEL:
                return this.translate('diesel');
            case FuelType.PETROL:
                return this.translate('petrol');
            case FuelType.PETROL_PLUS:
                return this.translate('petrolPlus');
            case FuelType.ELECTRIC:
                return this.translate('electric');
            default:
                return formatFuelType(mainFuelType);
        }
    }
    getTransmission(transmission) {
        switch (transmission) {
            case Transmission.AUTOMATIC:
                return this.translate('automaticTransmission');
            case Transmission.MANUAL:
                return this.translate('manualTransmission');
            default:
                return transmission;
        }
    }
    async handleClassSelection(classData) {
        this.slidingDirection = 'left';
        await this.selectClass({
            classId: classData.classId,
            className: classData.className,
        });
        tracking.selectOption(SELECTOR_TYPES.CLASS, classData.classId);
        this.navigate(STEPS.TWO_CHOOSE_BODY_TYPE);
    }
    get bodyTypes() {
        return this.getBodyTypesForClass(this.selectedClass.classId);
    }
    get priceReferences() {
        return this.outputsConfig.priceReferences ?? [];
    }
    get priceSuffix() {
        const key = 'priceSuffix';
        const translation = this.translate(key);
        return translation !== key ? ` ${translation}` : '';
    }
    get vehicles() {
        return this.getVehiclesForBodyType(this.selectedClass.classId, this.selectedBodytype.id);
    }
    get vehicleImage() {
        return this.getImageForBodyType(this.selectedClass.classId, this.selectedBodytype.id);
    }
    handleBodytypeSelection(bodyType) {
        this.slidingDirection = 'left';
        tracking.selectOption(SELECTOR_TYPES.BODY_TYPE, bodyType.id);
        this.selectBodyType({
            id: bodyType.id,
            name: bodyType.name,
        });
        this.navigate(STEPS.THREE_CHOOSE_MOTORIZATION);
    }
    handleMotorizationSelection({ typeId, name }) {
        tracking.selectOption(SELECTOR_TYPES.MOTORISATION);
        this.selectVehicle({
            typeId,
            name,
        });
        this.toggleVehicleSelector();
    }
    navigateBack() {
        this.slidingDirection = 'right';
        this.back();
    }
    mounted() {
        if (!this.isFullscreenDisabled) {
            this.scrollLockService.lock();
        }
    }
    beforeDestroy() {
        this.$emit('close');
        if (!this.isFullscreenDisabled) {
            this.scrollLockService.unlock();
        }
    }
};
VehicleSelectorBody = __decorate([
    Component({
        name: 'VehicleSelectorBody',
        components: {
            VehicleSelectorBodyType,
            VehicleSelectorModel,
            VehicleSelectorMotorization,
            VehicleSelectorNavigation,
            VehicleSelectorSlide,
            VehicleSelectorSlides,
            VehicleSelectorTitleStep,
        },
        computed: {
            ...mapState([MODULES.VMDS]),
            ...mapGetters([
                'formatting',
                'translate',
                'availableClasses',
                'getAvailableClasses',
                'getBodyTypesForClass',
                'getVehiclesForBodyType',
                'defaultCustomerType',
                'getImageForBodyType',
                'outputsConfig',
            ]),
            ...mapGetters(MODULES.VEHICLE_SELECTOR, [
                'currentStep',
                'selectedClass',
                'selectedBodytype',
                'selectedVehicle',
                'disableBackNavigation',
                'vehicleSelectorIsVisible',
                'isFullscreenDisabled',
            ]),
        },
        methods: {
            ...mapActions(MODULES.VEHICLE_SELECTOR, [
                'back',
                'navigate',
                'selectClass',
                'selectBodyType',
                'selectVehicle',
                'toggleVehicleSelector',
            ]),
        },
    })
], VehicleSelectorBody);
export default VehicleSelectorBody;
