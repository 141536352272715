import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VehicleSelectorSlides = class VehicleSelectorSlides extends Vue {
    direction;
    onSliding = 'fcis-vehicle-selector-slides--on-sliding';
    beforeEnter() {
        this.$el.classList.add(this.onSliding);
    }
    afterLeave() {
        this.$el.classList.remove(this.onSliding);
    }
};
__decorate([
    Prop({ default: 'left' })
], VehicleSelectorSlides.prototype, "direction", void 0);
VehicleSelectorSlides = __decorate([
    Component({ name: 'VehicleSelectorSlides' })
], VehicleSelectorSlides);
export default VehicleSelectorSlides;
