import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { APP_STATUS, appendScript, OCAPI_CONTAINER, OCAPI_BUTTON_TYPE, BUTTON_TYPE, } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import LoadingSpinner from '@/components/loading-spinner/loading-spinner.vue';
import { getMainFuelType } from '@/store/modules/vehicleSelector/utils';
import { v4 as v4uuid } from 'uuid';
import Toggle from '@/components/toggle/toggle.vue';
import { logger } from '@/utils/logger';
let CleopatraWrapper = class CleopatraWrapper extends Vue {
    /**
     * State properties
     */
    fcisWidget;
    market;
    locale;
    setDisclaimers;
    setTitle;
    setQuoteData;
    setButtons;
    setLegalBannerImage;
    setLegalBannerText;
    setAppStatus;
    setErrorMessages;
    setContactFormItems;
    setCustomerType;
    setCustomerTypeInput;
    cleoInitialised = false;
    currentVehicle;
    currentVehicleForOCAPI;
    customConfiguration;
    defaultCustomerType;
    defaultFinancingProduct;
    shouldDisplayCustomerTypeToggle;
    cleoCustomerTypeInput;
    customerTypeToggleValue;
    mounted() {
        this.fcisWidget = this.$refs.fcisWidget;
        this.initCleopatra();
    }
    async created() {
        const { VUE_APP_CLEOPATRA_SCRIPT_NO_MODULE = '', VUE_APP_CLEOPATRA_SCRIPT_MODULE = '' } = process.env;
        const aemEnv = (window.pageEnvironmentVariables || {}).stage;
        await appendScript(VUE_APP_CLEOPATRA_SCRIPT_MODULE.replace('{prefix}', aemEnv !== 'PROD' ? 'test.' : ''), {
            type: 'module',
        });
        await appendScript(VUE_APP_CLEOPATRA_SCRIPT_NO_MODULE.replace('{prefix}', aemEnv !== 'PROD' ? 'test.' : ''), {
            nomodule: '',
        });
    }
    async handleLinks(links = []) {
        const cleopatraButtons = links.filter((item) => item.classification === BUTTON_TYPE.LIST);
        const otherButtons = links.filter((item) => item.classification !== BUTTON_TYPE.LIST);
        // Initially, remove the buttons that are not of type LIST
        this.fcisWidget.setButtons(cleopatraButtons);
        // Set the new buttons to the store
        await this.setButtons(otherButtons);
    }
    async onFcisQuote(event) {
        this.cleoInitialised = true;
        if (!Object.keys(event.detail.containers).length) {
            logger.log(['No quote data from FCIS']);
        }
        const { containers, widgetTitle, links, messages, financingProduct } = event.detail;
        const outputContainer = containers.find((container) => container.id === OCAPI_CONTAINER.OUTPUT) || {};
        this.setCustomerType(financingProduct.customerType);
        await this.handleLinks(links);
        this.setTitle(widgetTitle);
        this.setErrorMessages(messages);
        this.setQuoteData(outputContainer.items);
        const regulatoryContainer = containers.find((container) => container.id === OCAPI_CONTAINER.REGULATORY);
        const urlParam = regulatoryContainer?.items.find(({ id }) => id === 'bannerUrl');
        const textParam = regulatoryContainer?.items.find(({ id }) => id === 'bannerText');
        const graphicsParam = regulatoryContainer?.items.find(({ id }) => id === 'bannerGraphics');
        this.setLegalBannerImage(graphicsParam?.value ?? urlParam?.value);
        this.setLegalBannerText(textParam?.value);
        this.setContactFormItems({
            modelClass: this.currentVehicle.classId,
            bodyType: this.currentVehicle.bodytypeId,
            modelName: this.currentVehicle.name,
            fuelType: getMainFuelType(this.currentVehicle.fuelTypes),
            transmissionType: this.currentVehicle.transmissionType,
            brand: this.getBrandName(),
            baumuster: this.currentVehicle.baumuster,
            modelYear: this.currentVehicle.modelYear,
            origin: 'FINCALC',
        });
        this.setAppStatus(APP_STATUS.OK);
    }
    getBrandName() {
        const { brand } = this.currentVehicleForOCAPI.vehicleConfiguration;
        const brands = {
            'mercedes-benz': 'Mercedes-Benz',
        };
        return brands[brand] || brand;
    }
    async dispatchActions(financialCode) {
        window.sessionStorage.setItem('swsp:FCIS_DATA', JSON.stringify({
            financialCode,
            baumuster: this.currentVehicle.baumuster,
        }));
    }
    async generateFinanceCode(buttonId) {
        const financialCode = v4uuid();
        if (buttonId === OCAPI_BUTTON_TYPE.CAR_CONFIGURATOR) {
            this.dispatchActions(financialCode);
        }
        else {
            this.setContactFormItems({
                financialCode,
            });
            this.$emit('financial-code-set');
        }
        await this.fcisWidget.changeVehicle({
            financialCode,
        });
    }
    onFcisError() {
        this.setAppStatus(APP_STATUS.WARNING);
    }
    onFcisCustomerType(event) {
        this.setCustomerTypeInput(event.detail);
        this.shouldDisplayCustomerTypeToggle(true);
    }
    onFcisDisclaimers({ detail }) {
        this.setDisclaimers(detail || []);
    }
    /**
     * Watchers
     */
    onVehicleUpdate(payload) {
        if (!payload) {
            return;
        }
        if (this.cleoInitialised) {
            this.fcisWidget.parameters = {
                ...this.fcisWidget.parameters,
                productCode: `${this.locale}__${payload.vehicleConfiguration.baumuster}`,
                vehicle: this.currentVehicleForOCAPI,
            };
        }
        else {
            this.initCleopatra();
        }
    }
    onCleoParameterChange() {
        this.shouldDisplayCustomerTypeToggle(false);
        this.setButtons([]);
        if (this.cleoInitialised) {
            this.cleoInitialised = false;
            // Force cleopatra props (ex. environment) to be set first
            this.$nextTick(() => {
                this.initCleopatra();
            });
        }
    }
    onCustomerTypeToggleValueChange() {
        this.fcisWidget.changeCustomerInput(this.customerTypeToggleValue);
    }
    initCleopatra() {
        this.fcisWidget.parameters = {
            caller: 'owdc',
            market: this.market,
            locale: this.locale,
            vehicle: this.currentVehicleForOCAPI,
            customConfig: this.customConfiguration,
            customer: {
                type: this.defaultCustomerType || null,
                productType: this.defaultFinancingProduct || null,
            },
            productCode: `${this.locale}__${this.currentVehicleForOCAPI.vehicleConfiguration.baumuster}`,
        };
    }
};
__decorate([
    Watch('currentVehicleForOCAPI')
], CleopatraWrapper.prototype, "onVehicleUpdate", null);
__decorate([
    Watch('market'),
    Watch('locale'),
    Watch('environment'),
    Watch('customConfiguration')
], CleopatraWrapper.prototype, "onCleoParameterChange", null);
__decorate([
    Watch('customerTypeToggleValue')
], CleopatraWrapper.prototype, "onCustomerTypeToggleValueChange", null);
CleopatraWrapper = __decorate([
    Component({
        name: 'CleopatraWrapper',
        components: { LoadingSpinner, Toggle },
        computed: {
            ...mapGetters([
                'market',
                'locale',
                'environment',
                'appStatus',
                'errorMessages',
                'currentVehicleForOCAPI',
                'currentVehicle',
                'customConfiguration',
                'defaultFinancingProduct',
                'defaultCustomerType',
                'displayCustomerTypeToggle',
                'cleoCustomerTypeInput',
                'customerTypeToggleValue',
                'isInsuranceAccordeonOpen',
            ]),
        },
        methods: {
            ...mapActions([
                'setDisclaimers',
                'setQuoteData',
                'setAppStatus',
                'setButtons',
                'setTitle',
                'setErrorMessages',
                'setContactFormItems',
                'setLegalBannerImage',
                'setLegalBannerText',
                'setCustomerType',
                'setCustomerTypeInput',
                'shouldDisplayCustomerTypeToggle',
            ]),
        },
        data() {
            return { APP_STATUS: APP_STATUS };
        },
    })
], CleopatraWrapper);
export default CleopatraWrapper;
