import { multipleClassQuery, singleClassQuery, singleVehicleEmissionConsumptionQuery, useApolloClient, } from '@/graphql';
import { logger } from '@/utils/logger';
import { ProductGroup } from '@/store/modules/vmds/types';
import { APP_BRAND } from '@/utils';
export var VehicleCategory;
(function (VehicleCategory) {
    VehicleCategory["COMMERCIAL"] = "COMMERCIAL";
    VehicleCategory["PRIVATE"] = "PRIVATE";
})(VehicleCategory || (VehicleCategory = {}));
export const validOcapiTaxesTypes = [
    'acquisitionTax',
    'automobileTax',
    'bpm',
    'carAssessmentFee',
    'compulsoryInsurance',
    'costExpenses',
    'delivery',
    'deliveryFee',
    'ecv',
    'frf',
    'garageFee',
    'garageHandlingFee',
    'inspectionFee',
    'inspectionHandlingFee',
    'ipt',
    'isv',
    'licensePlateFee',
    'maintenancePackageFee',
    'notarialFee',
    'nova',
    'otherNonTaxableFee',
    'preparationFee',
    'recycleDeposit',
    'recyclingFee',
    'registrationTax',
    'stampDutyFee',
    'vat',
    'ved',
    'vrp',
    'warrantFee',
    'weightTax',
];
/**
 * Fetch bodytypes belonging to class id
 * @param {any} options
 * @returns {Array<any>}
 */
export const fetchMultipleClasses = async (options) => {
    const { market, language, uri = '', subBrands = null, brand } = options;
    try {
        const apolloClient = useApolloClient(uri);
        const isVans = brand === APP_BRAND.VANS;
        const vehiclesCategory = isVans ? VehicleCategory.COMMERCIAL : VehicleCategory.PRIVATE;
        const productGroups = isVans ? [ProductGroup.VAN] : [ProductGroup.PASSENGER_CAR, ProductGroup.VAN];
        const { data } = await apolloClient.query({
            query: multipleClassQuery,
            variables: {
                market,
                language,
                filter: {
                    subBrands,
                    lifeCycles: 'FOR_SALE',
                    vehiclesCategory,
                    productGroups,
                },
            },
        });
        return data?.classBodytypeVehicles || [];
    }
    catch (e) {
        logger.error(e);
        return [];
    }
};
/**
 * Fetch vehicles belonging to modelSeries
 * @param {IModelSeriesFetchOptions} options
 * @returns {Array<any>}
 */
export const fetchSingleModel = async (options) => {
    const { market, language, uri = '', modelSeries = '', subBrands = null, engineConcept = null, brand } = options;
    try {
        const apolloClient = useApolloClient(uri);
        const isVans = brand === APP_BRAND.VANS;
        const vehiclesCategory = !isVans ? VehicleCategory.PRIVATE : null;
        const productGroups = isVans ? [ProductGroup.VAN] : [ProductGroup.PASSENGER_CAR, ProductGroup.VAN];
        const { data } = await apolloClient.query({
            query: singleClassQuery,
            variables: {
                market,
                language,
                modelSeries,
                filter: {
                    lifeCycles: ['FOR_SALE'],
                    productGroups,
                    subBrands,
                    vehiclesCategory,
                },
            },
        });
        if (data?.modelSeriesVehicles?.vehicles && engineConcept) {
            data.modelSeriesVehicles.vehicles = data.modelSeriesVehicles.vehicles.filter(({ technicalData: { engine } }) => engine.engineConcept === engineConcept);
        }
        return data?.modelSeriesVehicles || [];
    }
    catch (e) {
        logger.error(e);
        return [];
    }
};
/**
 * Fetch emission/consumption data for a specific vehicle
 * @param {IEmissionFetchOptions} options
 * @returns {Array<any>}
 */
export const fetchEmissionsAndConsumptionForVehicle = async (options) => {
    const { market, language, typeId, uri = '' } = options;
    try {
        const apolloClient = useApolloClient(uri);
        const { data } = await apolloClient.query({
            query: singleVehicleEmissionConsumptionQuery,
            variables: {
                market,
                language,
                typeId,
            },
        });
        return data?.typeIdVehicle?.technicalData || {};
    }
    catch (e) {
        logger.error(e);
        return {};
    }
};
