import { fetchEmissionsAndConsumptionForVehicle, fetchMultipleClasses, fetchSingleModel, validOcapiTaxesTypes, } from '@/graphql/services/vmds';
import { MODULES } from '@/store/modules';
import { APP_BRAND, APP_STATUS, CUSTOMER_TYPE, inputFormatting, getValidPriceTag, avoidCarsWithNoPrice, SubBrand, } from '@/utils';
import { formatFuelType, getMainFuelType, getMotorizationPayload } from '@/store/modules/vehicleSelector/utils';
import { Transmission, } from '@/store/modules/vmds/types';
import { filterClassesByBlackList, MAYBACH_SUBBRAND } from '@/store/modules/vmds/utils';
import { logger } from '@/utils/logger';
const state = {
    availableClasses: [],
    vehicleIsReady: false,
    currentVehicle: {
        baumuster: '',
        bodytypeId: '',
        bodytypeName: '',
        brand: '',
        changeYear: '',
        className: '',
        classId: '',
        condition: '',
        consumption: {},
        currency: '',
        division: '',
        emissions: {},
        engine: {},
        engineConcept: {},
        enginePower: {},
        environmentalDiscount: '',
        fuelTypes: [],
        listPrice: 0,
        modelSeriesFacelift: false,
        modelYear: '',
        name: '',
        netPrice: 0,
        nst: '',
        stagedImgURL: '',
        subBrands: [],
        taxes: [],
        transmissionType: '',
        typeId: '',
    },
};
/**
 * Fallback for stage images
 * When not available, uses cutouts image
 * Default size is medium
 */
export const handleImageURL = (images) => {
    const { cutouts, stage } = images;
    return stage ? `${stage}&crop=286,124,1726,1034&width=350` : cutouts?.medium ?? '';
};
export const handleVehicle = (vehicle) => {
    const { combustionEngine, electricEngine } = vehicle.technicalData.engine;
    const enginePower = [];
    const mainEnginePower = combustionEngine?.powerInKw || electricEngine?.powerInKw;
    if (mainEnginePower) {
        enginePower.push(mainEnginePower);
    }
    if (combustionEngine && electricEngine) {
        enginePower.push(electricEngine.powerInKw);
    }
    const electricEnginePower = electricEngine?.powerInKw?.value;
    const combustionEnginePower = combustionEngine?.powerInKw?.value;
    const listPrice = vehicle.financeData?.prices.manual?.price || vehicle.financeData?.prices.automatic?.price;
    const netPrice = vehicle.financeData?.prices.manual?.netPrice || vehicle.financeData?.prices.automatic?.netPrice;
    const discounts = vehicle.financeData?.discountPrices?.manual?.discounts || vehicle.financeData?.discountPrices?.automatic?.discounts;
    const manufacturerEnvironmentalBonus = discounts?.find((discount) => discount.type === 'MANUFACTURER_ENVIRONMENTAL_BONUS');
    const discountAmount = manufacturerEnvironmentalBonus?.amount;
    const taxes = vehicle.financeData?.prices.manual?.taxes || vehicle.financeData?.prices.automatic?.taxes;
    const validOcapiTaxesTypesLowerCase = validOcapiTaxesTypes.map((tax) => tax.toLowerCase());
    const filteredTaxes = taxes?.filter((tax) => {
        return validOcapiTaxesTypesLowerCase.includes(tax.name.toLowerCase());
    });
    return {
        baumuster: vehicle.baumuster,
        bodytypeId: vehicle.bodytypeId,
        bodytypeName: vehicle.bodytypeName,
        changeYear: vehicle.changeYear || '',
        classId: vehicle.classId,
        className: vehicle.className,
        combustionEnginePower,
        currency: vehicle.financeData?.currency,
        electricEnginePower,
        engineConcept: vehicle.technicalData.engine.engineConcept,
        enginePower,
        environmentalDiscount: discountAmount,
        fuelTypes: vehicle.technicalData.engine.fuelType,
        listPrice,
        modelSeriesFacelift: vehicle.modelSeriesFacelift,
        modelYear: vehicle.modelYear,
        name: vehicle.name,
        netPrice,
        nst: vehicle.nationalSalesType,
        productGroup: vehicle.productGroup,
        stagedImgURL: handleImageURL(vehicle.images),
        subBrands: vehicle.subBrands,
        taxes: filteredTaxes || [],
        technicalData: vehicle.technicalData,
        transmissionType: vehicle.technicalData.transmission?.defaultTransmission,
        typeId: vehicle.typeId,
    };
};
export const handleModels = (models, includeSubbrands) => {
    const allVehicles = [];
    models.forEach((model) => {
        model.vehicles.forEach((vehicle) => {
            if (includeSubbrands || !vehicle.subBrands?.includes(MAYBACH_SUBBRAND)) {
                allVehicles.push(handleVehicle(vehicle));
            }
        });
    });
    allVehicles?.sort((a, b) => a.listPrice - b.listPrice);
    return allVehicles;
};
export const handleBodytype = (bodytype, includeSubbrands) => {
    const vehicles = handleModels(bodytype.models, includeSubbrands);
    return {
        name: bodytype.bodytypeName,
        id: bodytype.bodytypeId,
        vehicles,
    };
};
export const handleClass = (vehicleClass, includeSubbrands) => {
    const bodies = vehicleClass.bodytypes.map((bodytype) => handleBodytype(bodytype, includeSubbrands));
    if (!bodies.length) {
        return null;
    }
    return {
        className: vehicleClass.className,
        classId: vehicleClass.classId,
        bodies,
    };
};
export const handleMaybachClass = (classes) => {
    const bodies = [];
    classes.forEach((vehicleClass) => {
        bodies.push(...vehicleClass.bodytypes.map((bodytype) => handleBodytype(bodytype, true)));
    });
    return {
        className: 'Mercedes Maybach',
        classId: 'maybach-class',
        bodies,
    };
};
export const handleEnergyEfficiencyClass = (energyEfficiencyClass) => {
    {
        switch (energyEfficiencyClass) {
            case 'A_TRIPLE_PLUS':
                return 'A+++';
            case 'A_DOUBLE_PLUS':
                return 'A++';
            case 'A_PLUS':
                return 'A+';
            default:
                return energyEfficiencyClass;
        }
    }
};
export function normalizeDefaultIndex(index) {
    if (index === -1) {
        return 0;
    }
    return index;
}
export function fetchVMDSEndpoint(configurations) {
    const endpoint = configurations?.vehicleMasterDataSource?.endpoint ?? '';
    const { VUE_APP_VMDS_ENDPOINT } = process.env;
    return VUE_APP_VMDS_ENDPOINT || endpoint;
}
const mutations = {
    SET_CLASSES: (state, data) => {
        // Freeze data and prevent Vuex from adding its getters and setters
        state.availableClasses = Object.freeze(avoidCarsWithNoPrice(data));
    },
    SET_VEHICLE_TECHNICAL_DATA: (state, data) => {
        state.currentVehicle = {
            ...state.currentVehicle,
            ...data,
        };
    },
    SET_CURRENT_VEHICLE: (state, data) => {
        state.currentVehicle = Object.freeze(data);
    },
    SET_VEHICLE_STATUS: (state, isReady) => {
        state.vehicleIsReady = isReady;
    },
};
const actions = {
    getMultipleClasses: async ({ rootState, rootGetters, commit, dispatch }, { typeId, applicationBrand, }) => {
        const isVans = applicationBrand === APP_BRAND.VANS;
        try {
            const vmdsEndpoint = fetchVMDSEndpoint(rootGetters.configurations);
            const defaultClasses = await fetchMultipleClasses({
                market: rootState.app.replaceMarket || rootState.app.market,
                language: rootState.app.replaceLanguage || rootState.app.language,
                uri: vmdsEndpoint,
                brand: applicationBrand,
            });
            const combinedClasses = [...defaultClasses.map((item) => handleClass(item))];
            if (!isVans) {
                const maybachClass = await fetchMultipleClasses({
                    market: rootState.app.replaceMarket || rootState.app.market,
                    language: rootState.app.replaceLanguage || rootState.app.language,
                    subBrands: [SubBrand.MERCEDES_MAYBACH],
                    uri: vmdsEndpoint,
                });
                if (maybachClass.length) {
                    combinedClasses.push(handleMaybachClass(maybachClass));
                }
            }
            const blacklistClasses = rootState.app.configurations.modelsBlackList;
            commit('SET_CLASSES', filterClassesByBlackList(combinedClasses, blacklistClasses));
            let classIndex = 0;
            let defaultClassId = '';
            let defaultBodyTypeId;
            if (typeId) {
                combinedClasses.forEach((vehicle) => {
                    vehicle.bodies.forEach((vehicleBodies) => {
                        const vehicleTypeId = vehicleBodies.vehicles.find((vehicles) => vehicles.typeId === typeId);
                        if (vehicleTypeId) {
                            defaultClassId = vehicleTypeId.classId;
                            defaultBodyTypeId = vehicleTypeId.bodytypeId;
                        }
                    });
                });
                classIndex = normalizeDefaultIndex(combinedClasses.findIndex((vehicleClass) => vehicleClass.classId === defaultClassId));
            }
            dispatch(`${MODULES.VEHICLE_SELECTOR}/setDefaults`, {
                vehicleClass: combinedClasses[classIndex],
                bodytypeId: defaultBodyTypeId,
                typeId,
            });
        }
        catch (e) {
            logger.error(e);
        }
    },
    getSingleClass: async ({ rootState, rootGetters, commit, dispatch }, { typeId, modelSeries, subBrands, applicationBrand, engineConcept, }) => {
        try {
            const vmdsEndpoint = fetchVMDSEndpoint(rootGetters.configurations);
            const modelData = await fetchSingleModel({
                market: rootState.app.replaceMarket || rootState.app.market,
                language: rootState.app.replaceLanguage || rootState.app.language,
                uri: vmdsEndpoint,
                modelSeries,
                subBrands,
                engineConcept,
                brand: applicationBrand,
            });
            const body = {
                bodytypeId: modelData.bodytypeData?.id || '',
                bodytypeName: modelData.bodytypeData?.name || '',
                models: [modelData],
            };
            const vehicleClass = {
                classId: modelData.classData?.id || '',
                className: modelData.classData?.name || '',
                bodytypes: [body],
            };
            const transformedClass = handleClass(vehicleClass, true);
            commit('SET_CLASSES', [transformedClass]);
            dispatch(`${MODULES.VEHICLE_SELECTOR}/setDefaults`, {
                vehicleClass: transformedClass,
                typeId,
            });
        }
        catch (e) {
            logger.error(e);
            dispatch('setAppStatus', APP_STATUS.ERROR);
        }
    },
    getVehicleTechnicalData: async ({ rootState, rootGetters, commit, dispatch }, typeId) => {
        const vmdsEndpoint = fetchVMDSEndpoint(rootGetters.configurations);
        const { body, emissions, consumption, transmission, engine, } = await fetchEmissionsAndConsumptionForVehicle({
            market: rootState.app.replaceMarket || rootState.app.market,
            language: rootState.app.replaceLanguage || rootState.app.language,
            typeId,
            uri: vmdsEndpoint,
        });
        const isDefaultAutomatic = transmission.defaultTransmission === Transmission.AUTOMATIC;
        const defaultBodyWeights = isDefaultAutomatic ? body?.weights?.automatic : body?.weights?.manual;
        let defaultEmissions = isDefaultAutomatic ? emissions?.automatic : emissions?.manual;
        const defaultConsumption = isDefaultAutomatic ? consumption?.automatic : consumption?.manual;
        const defaultWltpRange = isDefaultAutomatic
            ? engine?.electricEngine?.wltpRange?.automatic
            : engine?.electricEngine?.wltpRange?.manual;
        if (defaultEmissions) {
            defaultEmissions = {
                ...defaultEmissions,
                energyEfficiencyClass: handleEnergyEfficiencyClass(defaultEmissions.energyEfficiencyClass),
            };
        }
        commit('SET_VEHICLE_TECHNICAL_DATA', {
            emissions: defaultEmissions,
            consumption: defaultConsumption,
            engine: {
                electricEngine: {
                    wltpRange: defaultWltpRange,
                },
                combustionEngine: {
                    ...engine?.combustionEngine,
                },
            },
            body: {
                ...body,
                weights: defaultBodyWeights,
            },
        });
        commit('SET_VEHICLE_STATUS', true);
        dispatch('setAppStatus', APP_STATUS.OK);
    },
    selectVehicle: async ({ state, commit, dispatch }, { classId, bodyTypeId, typeId, }) => {
        commit('SET_VEHICLE_STATUS', false);
        const selectedClassBodies = state.availableClasses.find((item) => item.classId === classId)?.bodies;
        const selectedVehicles = selectedClassBodies?.find((body) => body.id === bodyTypeId)?.vehicles;
        const vehicle = selectedVehicles?.find((item) => item.typeId === typeId);
        if (vehicle) {
            commit('SET_CURRENT_VEHICLE', vehicle);
            dispatch('getVehicleTechnicalData', vehicle.typeId);
        }
        else {
            logger.error(`${classId} ${bodyTypeId} ${typeId} Failed to fetch`);
        }
    },
};
export const VMDSModule = {
    state,
    actions,
    mutations,
    getters: {
        availableClasses: (state) => state.availableClasses,
        currentEmissions: (state) => state.currentVehicle.emissions,
        currentEmission: (state) => state.currentVehicle.emission,
        currentConsumption: (state) => state.currentVehicle.consumption,
        currentEngine: (state) => state.currentVehicle.engine,
        currentEngineConcept: (state) => state.currentVehicle.engineConcept,
        currentFuelTypes: (state) => state.currentVehicle.fuelTypes,
        getAvailableClasses: (state) => {
            return state.availableClasses.map((item) => {
                return {
                    classId: item.classId,
                    className: item.className,
                };
            });
        },
        getBodyTypesForClass: (state) => (classId) => {
            return state.availableClasses.find((item) => item.classId === classId)?.bodies;
        },
        getVehiclesForBodyType: (state) => (classId, bodyTypeId) => {
            const selectedClassBodies = state.availableClasses.find((item) => item.classId === classId)?.bodies;
            return selectedClassBodies.find((body) => body.id === bodyTypeId)?.vehicles;
        },
        getImageForBodyType: (state) => (classId, bodyTypeId) => {
            const selectedClassBodies = state.availableClasses.find((item) => item.classId === classId)?.bodies;
            const availableVehicles = selectedClassBodies.find((body) => body.id === bodyTypeId)?.vehicles;
            return availableVehicles?.[0].stagedImgURL;
        },
        currentVehicleFuelType: (state) => formatFuelType(getMainFuelType(state.currentVehicle.fuelTypes)),
        currentVehicle: (state) => state.currentVehicle,
        currentVehiclePrice: (state, rootGetters) => {
            const isBusiness = rootGetters.defaultCustomerType === CUSTOMER_TYPE.BUSINESS;
            const priceTag = getValidPriceTag(state.currentVehicle, isBusiness);
            return inputFormatting(state.currentVehicle[priceTag], rootGetters.formatting);
        },
        currentVehicleEnvironmentalDiscount: (state) => state.currentVehicle.environmentalDiscount,
        currentVehicleForOCAPI: (state, getters) => state.vehicleIsReady ? getMotorizationPayload(state.currentVehicle, getters.applicationBrand) : null,
    },
};
