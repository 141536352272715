import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import BaseButton from '@/components/base-button/base-button.vue';
import { mapGetters } from 'vuex';
import { BUTTON_TYPE } from '@/utils';
import { logger } from '@/utils/logger';
let VehicleTileFooter = class VehicleTileFooter extends Vue {
    getButtonTypeForClassification(classification) {
        switch (classification) {
            case BUTTON_TYPE.CALL_TO_ACTION:
                return 'primary';
            default:
                return 'secondary';
        }
    }
    handleClick(id) {
        logger.info(`button with id: ${id} clicked`);
        this.$emit('button-clicked', id);
    }
};
VehicleTileFooter = __decorate([
    Component({
        name: 'VehicleTileFooter',
        components: { BaseButton },
        computed: {
            ...mapGetters(['buttons']),
        },
    })
], VehicleTileFooter);
export default VehicleTileFooter;
