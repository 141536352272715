import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VehicleSelectorTitleStep = class VehicleSelectorTitleStep extends Vue {
    title;
    vehicleImg;
};
__decorate([
    Prop({ required: true })
], VehicleSelectorTitleStep.prototype, "title", void 0);
__decorate([
    Prop({ required: false })
], VehicleSelectorTitleStep.prototype, "vehicleImg", void 0);
VehicleSelectorTitleStep = __decorate([
    Component({ name: 'VehicleSelectorTitleStep' })
], VehicleSelectorTitleStep);
export default VehicleSelectorTitleStep;
