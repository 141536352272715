import { __decorate } from "tslib";
import { ERROR } from '@/utils/labels';
import { logger } from '@/utils/logger';
import { Component, Vue, Prop } from 'vue-property-decorator';
let Error = class Error extends Vue {
    code;
    /**
     * Computed properties
     */
    get errorMessage() {
        return ERROR[this.code] || ERROR['DEFAULT'];
    }
    /**
     * Lifecycle methods
     */
    mounted() {
        logger.error(ERROR[this.code] || ERROR['DEFAULT']);
    }
};
__decorate([
    Prop({ required: true })
], Error.prototype, "code", void 0);
Error = __decorate([
    Component({ name: 'Error' })
], Error);
export default Error;
