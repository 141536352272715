import Vue from 'vue';
import Vuex from 'vuex';
import { APP_STATUS, APP_TYPE, ERROR, getUrlPrefix, FINANCE_TYPE, CUSTOMER_TYPE, getDescendantProp, APP_BRAND, isDemoMarket, } from '@/utils';
import { OutputsModule } from './modules/OutputsModule';
import { setLocalEnvironment } from '@/utils/localEnvironment';
import { VehicleSelector } from './modules/vehicleSelector/VehicleSelector';
import { VMDSModule } from '@/store/modules/vmds/VMDSModule';
import { CarConfigurationModule } from '@/store/modules/carConfiguration/CarConfigurationModule';
import deeplinkService from '@/services/DeeplinkService';
import CarConfigurationService from '@/services/CarConfigurationService';
import { logger } from '@/utils/logger';
const isVans = (state) => {
    const productGroup = state.VMDSModule?.currentVehicle?.productGroup ?? '';
    if (productGroup) {
        return productGroup.toLowerCase() === 'van';
    }
    return state.app?.applicationBrand === APP_BRAND.VANS;
};
const defaultState = {
    app: {
        appStatus: {
            errorCode: '',
            code: APP_STATUS.LOADING,
        },
        translations: {},
        environment: '',
        language: '',
        replaceMarket: '',
        replaceLanguage: '',
        market: '',
        locale: '',
        localeFormat: '',
        appType: '',
        applicationBrand: '',
        defaultFinancingProduct: FINANCE_TYPE.LEASING,
        defaultCustomerType: CUSTOMER_TYPE.PRIVATE,
        vehicle: {},
        customConfiguration: {},
        configurations: {
            calculatorURL: '',
            aemFormUrl: '',
            ocapiParams: {
                url: '',
                version: '',
            },
            inputs: {},
            outputs: {
                wltp: {
                    type: '',
                    items: [],
                    isEcoRatingEnabled: false,
                },
                isInsuranceAccordeonOpen: false,
                buttons: [],
            },
            formatting: {},
            modelsBlackList: [],
            vehicleMasterDataSource: {
                specificModel: '',
                multipleModels: '',
                endpoint: '',
            },
            leadAndContactFormUrl: '',
        },
        errorMessages: [],
    },
};
Vue.use(Vuex);
export const mutations = {
    SET_INIT_DATA: (state, data) => {
        state.app = { ...state.app, ...data };
    },
    SET_CONFIGURATIONS: (state, configData) => {
        state.app.configurations = setLocalEnvironment(configData);
    },
    SET_TRANSLATIONS: (state, translations) => {
        state.app.translations = translations;
    },
    SET_CUSTOM_CONFIGURATION: (state, data) => {
        state.app.customConfiguration = data;
    },
    SET_CUSTOMER_TYPE: (state, data) => {
        state.app.defaultCustomerType = data;
    },
    SET_APP_STATUS: (state, { status, errorCode }) => {
        state.app.appStatus = { code: status, errorCode };
    },
    SET_ERROR_MESSAGES: (state, messages) => {
        state.app.errorMessages = messages;
    },
};
export const getters = {
    configurations: (state) => state.app.configurations,
    customConfiguration: (state) => state.app.customConfiguration,
    localeFormat: (state) => state.app.localeFormat,
    locale: (state) => state.app.locale,
    market: (state) => state.app.market,
    outputsConfig: (state) => state.app.configurations.outputs,
    appStatus: (state) => state.app.appStatus.code,
    appErrorCode: (state) => state.app.appStatus.errorCode,
    formatting: (state) => state.app.configurations.formatting,
    appType: (state) => state.app.appType,
    isModelSelector: (state) => state.app.appType === APP_TYPE.MODEL_SELECTOR,
    environment: (state) => state.app.environment,
    wltpItems: (state) => state.app.configurations.outputs.wltp.items || [],
    translate: (state) => (key) => {
        const translation = getDescendantProp(key, state.app.translations);
        if (!translation) {
            logger.info(`missing translation for ${key}`);
        }
        return translation || key;
    },
    modelsBlackList: (state) => state.app.configurations.modelsBlackList || [],
    aemFormURL: (state) => state.app.configurations.aemFormUrl,
    errorMessages: (state) => state.app.errorMessages,
    defaultFinancingProduct: (state) => state.app.defaultFinancingProduct,
    defaultCustomerType: (state) => state.app.defaultCustomerType,
    applicationBrand: (state) => state.app.applicationBrand,
    isEcoRatingEnabled: (state) => state.app.configurations.outputs.wltp.isEcoRatingEnabled,
    isInsuranceAccordeonOpen: (state) => state.app.configurations.outputs.isInsuranceAccordeonOpen,
};
export default new Vuex.Store({
    state: defaultState,
    mutations,
    actions: {
        initialiseApplication: async ({ dispatch, commit }, data) => {
            // Demo market should be treated differently since VMDS does not recognize
            // any of the demo markets.
            if (isDemoMarket(data.locale)) {
                data.replaceMarket = 'DE';
                data.replaceLanguage = 'de';
            }
            // 1. Here is where everything begins!
            commit('SET_INIT_DATA', data);
            commit('SET_CUSTOM_CONFIGURATION', data.customConfiguration);
            deeplinkService.setMarketAndLanguage(data.replaceMarket || data.market, data.replaceLanguage || data.language, data.environment);
            CarConfigurationService.setConfig(data.replaceMarket || data.market, data.replaceLanguage || data.language, data.environment);
            // Get configuration from FCIS Configuration Service
            dispatch('getConfigurations');
        },
        getConfigurations: async ({ state, dispatch, commit }) => {
            const currentEnvironment = state.app.environment || process.env.NODE_ENV;
            const prefix = getUrlPrefix(currentEnvironment);
            //NOTE: LEAVE FOR EASY DEBUG OF CONFIG
            // const response = await fetch(
            //   `/fixtures/owdc/${state.app.market}/${state.app.locale}.json`,
            // );
            await dispatch('updateConfigurations', null);
            try {
                const translationsResponse = await fetch(
                // `http://localhost:3000/translations/${state.app.market}/${state.app.language}_${state.app.market}.json`,
                `https://${prefix}assets.oneweb.mercedes-benz.com/plugin/fcis/fcis-config/translations/${state.app.market}/${state.app.language}_${state.app.market}.json`);
                const translations = await translationsResponse.json();
                commit('SET_TRANSLATIONS', translations);
                if (state.app.appType === APP_TYPE.SIMPLE_CALCULATOR) {
                    const subBrands = state.app.vehicle.subBrands || [];
                    const engineConcept = state.app.vehicle.engineConcept;
                    if (state.app.vehicle.subBrand) {
                        subBrands.push(state.app.vehicle.subBrand);
                    }
                    // Get Data for the current Class on VMDSModule
                    await dispatch('getSingleClass', {
                        typeId: state.app.vehicle.typeId,
                        modelSeries: state.app.vehicle.modelSeries,
                        subBrands,
                        engineConcept,
                        applicationBrand: state.app.applicationBrand,
                    });
                }
                else if (state.app.appType === APP_TYPE.MODEL_SELECTOR) {
                    await dispatch('getMultipleClasses', {
                        typeId: state.app.vehicle.typeId,
                        applicationBrand: state.app.applicationBrand,
                    });
                }
            }
            catch (error) {
                dispatch('setAppStatus', APP_STATUS.ERROR, ERROR.MARKET_UNAVAILABLE);
            }
        },
        updateConfigurations: async ({ state, dispatch, commit }) => {
            const currentEnvironment = state.app.environment || process.env.NODE_ENV;
            const prefix = getUrlPrefix(currentEnvironment);
            try {
                let url;
                if (isVans(state)) {
                    // url = `http://localhost:3000/v2/vans/owdc/${state.app.market}/${state.app.locale}.json`;
                    url = `https://${prefix}assets.oneweb.mercedes-benz.com/plugin/fcis/fcis-config/v2/vans/owdc/${state.app.market}/${state.app.locale}.json`;
                }
                else {
                    // url = `http://localhost:3000/v2/owdc/${state.app.market}/${state.app.locale}.json`;
                    url = `https://${prefix}assets.oneweb.mercedes-benz.com/plugin/fcis/fcis-config/v2/owdc/${state.app.market}/${state.app.locale}.json`;
                }
                const response = await fetch(url);
                const configs = await response.json();
                commit('SET_CONFIGURATIONS', configs);
            }
            catch (error) {
                dispatch('setAppStatus', APP_STATUS.ERROR, ERROR.MARKET_UNAVAILABLE);
            }
        },
        setCustomerType({ commit }, customerType) {
            commit('SET_CUSTOMER_TYPE', customerType);
        },
        setAppStatus({ commit }, status, errorCode = null) {
            commit('SET_APP_STATUS', { status, errorCode });
        },
        cleanErrorMessages: ({ commit }) => {
            // 100 ms before removing the notification from the store in order to give
            // WB time to perform the cool animatation when removing the notification
            const TIMEOUT = 100;
            const timer = window.setTimeout(() => {
                commit('SET_ERROR_MESSAGES', []);
                window.clearTimeout(timer);
            }, TIMEOUT);
        },
        setErrorMessages: ({ commit }, payload = []) => {
            const messages = Array.isArray(payload) ? payload : [];
            commit('SET_ERROR_MESSAGES', messages);
        },
    },
    getters,
    modules: {
        VMDSModule,
        OutputsModule,
        VehicleSelector,
        CarConfigurationModule,
    },
});
export { defaultState };
