/* eslint-disable @typescript-eslint/no-var-requires */

const packageMeta = require('./package.json');
const seamlessRc = require('./../../.seamlessrc.json');

const NAME = 'owdc';
const TENANT = seamlessRc.tenantId;
const pluginPath = `/${TENANT}-${NAME}/${TENANT}-${NAME}-${packageMeta.version}`;
const fileName = `${TENANT}-${NAME}`;

const scripts = [
  {
    url: `${pluginPath}/${fileName}.vendors~${fileName}.min.min.js`,
    attrs: {
      type: 'module', // vendor
    },
  },
  {
    url: `${pluginPath}/${fileName}.min.js`,
    attrs: {
      type: 'module', // component
    },
  },
  {
    url: `${pluginPath}/${fileName}.vendors~${fileName}.es5.min.js`,
    attrs: {
      nomodule: '', //vendor old browsers remove its not supported
    },
  },
  {
    url: `${pluginPath}/${fileName}.es5.min.js`,
    attrs: {
      nomodule: '', //component old browsers remove its not supported
    },
  },
];

const marketAvailability = [
  {
    language: 'de',
    country: 'AT',
  },
  {
    language: 'en',
    country: 'AU',
  },
  {
    language: 'fr',
    country: 'BE',
  },
  {
    language: 'nl',
    country: 'BE',
  },
  {
    language: 'de',
    country: 'CH',
  },
  {
    language: 'fr',
    country: 'CH',
  },
  {
    language: 'it',
    country: 'CH',
  },
  {
    language: 'cs',
    country: 'CZ',
  },
  {
    language: 'de',
    country: 'DE',
  },
  {
    language: 'da',
    country: 'DK',
  },
  {
    language: 'es',
    country: 'ES',
  },
  {
    language: 'fr',
    country: 'FR',
  },
  {
    language: 'hu',
    country: 'HU',
  },
  {
    language: 'en',
    country: 'IN',
  },
  {
    language: 'it',
    country: 'IT',
  },
  {
    language: 'ja',
    country: 'JP',
  },
  {
    language: 'ko',
    country: 'KR',
  },
  {
    language: 'de',
    country: 'LU',
  },
  {
    language: 'fr',
    country: 'LU',
  },
  {
    language: 'en',
    country: 'MY',
  },
  {
    language: 'nl',
    country: 'NL',
  },
  {
    language: 'en',
    country: 'NZ',
  },
  {
    language: 'pl',
    country: 'PL',
  },
  {
    language: 'pt',
    country: 'PT',
  },
  {
    language: 'ro',
    country: 'RO',
  },
  {
    language: 'ru',
    country: 'RU',
  },
  {
    language: 'sv',
    country: 'SE',
  },
  {
    language: 'en',
    country: 'SG',
  },
  {
    language: 'sk',
    country: 'SK',
  },
  {
    language: 'th',
    country: 'TH',
  },
  {
    language: 'en',
    country: 'TH',
  },
  {
    language: 'tr',
    country: 'TR',
  },
  {
    language: 'zh',
    country: 'TW',
  },
  {
    language: 'en',
    country: 'ZA',
  },
];

const dialog = [
  {
    widgetType: 'radio',
    param: 'applicationBrand',
    label: 'Select the Brand for the application',
    hint: 'Cars or Vans calculator',
    defaultValue: 'cars',
    options: [
      {
        label: 'Mercedes-Benz Cars',
        value: 'cars',
        selected: true,
      },
      {
        label: 'Vans',
        value: 'vans',
      },
    ],
  },
  {
    widgetType: 'radio',
    param: 'applicationType',
    label: 'Select the Application Type',
    hint: 'Single or Multiple Class Calculator',
    defaultValue: 'multiple',
    options: [
      {
        label: 'Single',
        value: 'single',
      },
      {
        label: 'Multiple',
        value: 'multiple',
        selected: true,
      },
    ],
  },
  {
    widgetType: 'text',
    label: 'TypeId',
    param: 'typeId',
    hint: 'Identification for a specific motorisation within VMDS, also known as typeId. e.g. 1771101-802',
  },
  {
    widgetType: 'dropdown',
    param: 'defaultCustomerType',
    defaultValue: '',
    label: 'Select a default Customer Type',
    hint: 'The Customer Type which will be used to initialise the Calculator. This value will be overwritten by the type of user logged in markets DE and AT.',
    options: [
      {
        label: 'none',
        value: '',
        selected: true,
      },
      {
        label: 'Private',
        value: 'private',
      },
      {
        label: 'Business',
        value: 'business',
      },
    ],
  },
  {
    widgetType: 'dropdown',
    param: 'defaultFinancingProduct',
    label: 'Select a default Financing Product',
    defaultValue: '',
    hint: 'The Financing Product which will be used to initialise the Calculator',
    options: [
      {
        label: 'none',
        value: '',
        selected: true,
      },
      {
        label: 'Leasing',
        value: 'leasing',
      },
      {
        label: 'Financing',
        value: 'financing',
      },
    ],
  },
];

module.exports = {
  manifestVersion: '2.0',
  name: `${TENANT}-${NAME}`,
  version: packageMeta.version,
  config: {
    hasCss: false,
    files: {
      scripts,
    },
    dependencies: {
      oneweb: '2.0',
    },
    isUserSessionRequired: false,
    integrationType: 'webcomponent',
    marketAvailability,
  },
  aemComponentConfig: {
    title: 'FCIS OWDC Web Component',
    description: packageMeta.description,
    componentGroup: 'Generic NG - Content',
    productPrefix: seamlessRc.tenantId,
    tagNameWithoutPrefix: 'owdc',
    dialog,
    nonAuthorableComponents: [
      {
        name: 'pls-pls-ui',
        version: 3,
      },
      {
        name: 'owcc-energy-consumption-labeling',
        version: 3,
      },
    ],
  },
};
