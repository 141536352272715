import { __decorate } from "tslib";
import { Prop, Vue, Component } from 'vue-property-decorator';
let EcoRating = class EcoRating extends Vue {
    // Props
    locale;
    market;
    currentEmissions;
    value;
    // Computed properties
    get getLocale() {
        return this.locale ? this.locale.replace('-', '_') : '';
    }
};
__decorate([
    Prop()
], EcoRating.prototype, "locale", void 0);
__decorate([
    Prop()
], EcoRating.prototype, "market", void 0);
__decorate([
    Prop()
], EcoRating.prototype, "currentEmissions", void 0);
__decorate([
    Prop({ required: true })
], EcoRating.prototype, "value", void 0);
EcoRating = __decorate([
    Component({ name: 'EcoRating' })
], EcoRating);
export default EcoRating;
