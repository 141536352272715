import { OCAPI_BUTTON_TYPE } from '@/utils';
const BASE_URL = {
    test: 'https://test.api.oneweb.mercedes-benz.com/vehicle-deeplinks-api/v1/deeplinks',
    production: 'https://api.oneweb.mercedes-benz.com/vehicle-deeplinks-api/v1/deeplinks',
    development: 'https://test.api.oneweb.mercedes-benz.com/vehicle-deeplinks-api/v1/deeplinks',
};
export const supportedButtons = [
    OCAPI_BUTTON_TYPE.TEST_DRIVE,
    OCAPI_BUTTON_TYPE.CAR_CONFIGURATOR,
    OCAPI_BUTTON_TYPE.SHOWROOM_STOCK,
];
class DeeplinkService {
    market = '';
    language = '';
    baseUrl = '';
    setMarketAndLanguage(market, language, environment) {
        this.market = market;
        this.language = language;
        const base = BASE_URL[environment] || BASE_URL['production'];
        this.baseUrl = `${base}/${this.market}/${this.language}`;
    }
    translateKey(key) {
        switch (key) {
            case 'CONFIGURATOR':
                return OCAPI_BUTTON_TYPE.CAR_CONFIGURATOR;
            case 'ONLINE_SHOP':
                return OCAPI_BUTTON_TYPE.SHOWROOM_STOCK;
            case 'TEST_DRIVE':
                return OCAPI_BUTTON_TYPE.TEST_DRIVE;
            default:
                return key;
        }
    }
    async fetchLinkForTypeId(typeId) {
        const url = `${this.baseUrl}/type-id/${typeId}`;
        const response = await fetch(url);
        const result = await response.json();
        return Object.keys(result).map((key) => ({
            ...result[key],
            id: this.translateKey(key),
        }));
    }
}
const deeplinkService = new DeeplinkService();
export default deeplinkService;
