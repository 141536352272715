import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { MODULES } from '@/store/modules';
import { capitalize, formatFuelType, getMainFuelType } from '@/store/modules/vehicleSelector/utils';
import ConsumptionComponent from '@/components/consumption/consumption-component.vue';
import { CUSTOMER_TYPE, isHybrid, getValidPriceTag, plsMarkets } from '@/utils';
import { FuelType } from '@/store/modules/vmds/types';
import EmissionComponent from '../emission-component/emission-component.vue';
import Modal from '@/components/modal/modal.vue';
import EcoRating from '@/components/eco-rating/eco-rating.vue';
import PlsPriceUi from '@/components/pls-price-ui/pls-price-ui.vue';
import { tracking } from '@/utils/TrackingManager';
let VehicleCard = class VehicleCard extends Vue {
    currentVehicle;
    translate;
    defaultCustomerType;
    isModelSelector;
    outputsConfig;
    currentEmissions;
    market;
    toggleVehicleSelector;
    locale;
    currentConsumption;
    currentEngine;
    environmentalDiscountModalIsOpen = false;
    toggleEnvironmentalDiscountModal() {
        this.environmentalDiscountModalIsOpen = !this.environmentalDiscountModalIsOpen;
    }
    get formattedTransmission() {
        return capitalize(this.currentVehicle.transmissionType);
    }
    get fuelType() {
        const mainFuelType = getMainFuelType(this.currentVehicle.fuelTypes);
        switch (mainFuelType) {
            case FuelType.DIESEL:
                return this.translate('diesel');
            case FuelType.PETROL:
                return this.translate('petrol');
            case FuelType.PETROL_PLUS:
                return this.translate('petrolPlus');
            case FuelType.ELECTRIC:
                return this.translate('electric');
            default:
                return formatFuelType(mainFuelType);
        }
    }
    get isHybrid() {
        return isHybrid(this.currentVehicle.engineConcept);
    }
    get formattedFuelType() {
        if (this.isHybrid) {
            return `${this.translate('hybrid')} (${this.fuelType}) `;
        }
        return this.fuelType;
    }
    get priceReferences() {
        return this.outputsConfig.priceReferences ?? [];
    }
    get priceSuffix() {
        const key = 'priceSuffix';
        const translation = this.translate(key);
        return translation !== key ? ` ${translation}` : '';
    }
    get priceType() {
        if (!this.defaultCustomerType) {
            return '';
        }
        const isBusiness = this.defaultCustomerType === CUSTOMER_TYPE.BUSINESS;
        const priceTag = getValidPriceTag(this.currentVehicle, isBusiness);
        return priceTag === 'netPrice' ? this.translate('vatExcluded') : this.translate('vatIncluded');
    }
    get openVehicleSelectorButtonLabel() {
        return this.isModelSelector ? this.translate('chooseAnotherModel') : this.translate('chooseAnotherBodyType');
    }
    get environmentalDiscountText() {
        if (getMainFuelType(this.currentVehicle.fuelTypes) === FuelType.ELECTRIC) {
            return this.translate('environmentalDiscountEletric');
        }
        return this.translate('environmentalDiscountHybrid');
    }
    get efficiencyRating() {
        return this.currentEmissions?.energyEfficiencyClass;
    }
    get isWLTPv2() {
        return this.outputsConfig.wltp.type === 'v2';
    }
    get isValidEfficiencyRating() {
        const validRatings = ['A+++', 'A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];
        return validRatings.includes(this.efficiencyRating);
    }
    get connectorWordTranslation() {
        if (this.market !== 'DE') {
            return '';
        }
        return this.translate('upTo');
    }
    get shouldShowPls() {
        return plsMarkets.includes(this.market);
    }
    get enginePower() {
        const { enginePower } = this.currentVehicle;
        const [firstEngine, secondEngine] = enginePower;
        const firstEnginePower = firstEngine ? `${firstEngine.value} ${firstEngine.unit}` : null;
        if (secondEngine) {
            const secondEnginePower = `${secondEngine.value} ${secondEngine.unit}`;
            return `${firstEnginePower} + ${this.connectorWordTranslation} ${secondEnginePower}`;
        }
        return firstEnginePower;
    }
    onVehicleSelectorClick() {
        tracking.update('change_vehicle');
        this.toggleVehicleSelector();
    }
};
VehicleCard = __decorate([
    Component({
        name: 'VehicleCard',
        components: {
            ConsumptionComponent,
            EmissionComponent,
            Modal,
            EcoRating,
            PlsPriceUi,
        },
        computed: {
            ...mapGetters([
                'translate',
                'isModelSelector',
                'currentVehicle',
                'currentVehiclePrice',
                'defaultCustomerType',
                'market',
                'outputsConfig',
                'currentEmissions',
                'locale',
                'isEcoRatingEnabled',
                'currentConsumption',
                'currentEngine',
            ]),
        },
        methods: {
            ...mapActions(MODULES.VEHICLE_SELECTOR, ['toggleVehicleSelector']),
        },
    })
], VehicleCard);
export default VehicleCard;
