import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { capitalize } from '@/store/modules/vehicleSelector/utils';
import { mapGetters } from 'vuex';
import { isHybrid } from '@/utils';
let VehicleDetail = class VehicleDetail extends Vue {
    translate;
    market;
    fuelType;
    power;
    transmission;
    fuelTypeLabel;
    enginePowerLabel;
    transmissionLabel;
    engineConcept;
    hybridTranslation;
    get formattedTransmission() {
        return capitalize(this.transmission);
    }
    get formattedFuelType() {
        const fuelTypeFormatted = capitalize(this.fuelType);
        if (isHybrid(this.engineConcept)) {
            return `${this.hybridTranslation} (${fuelTypeFormatted}) `;
        }
        return fuelTypeFormatted;
    }
    get connectorWordTranslation() {
        if (this.market !== 'DE') {
            return '';
        }
        return this.translate('upTo');
    }
    get enginePower() {
        const [firstEngine, secondEngine] = this.power;
        const firstEnginePower = firstEngine ? `${firstEngine.value} ${firstEngine.unit}` : '';
        if (secondEngine) {
            const secondEnginePower = `${secondEngine.value} ${secondEngine.unit}`;
            return `${firstEnginePower} + ${this.connectorWordTranslation} ${secondEnginePower}`;
        }
        return firstEnginePower;
    }
};
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "fuelType", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "power", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "transmission", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "fuelTypeLabel", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "enginePowerLabel", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "transmissionLabel", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "engineConcept", void 0);
__decorate([
    Prop({ required: true })
], VehicleDetail.prototype, "hybridTranslation", void 0);
VehicleDetail = __decorate([
    Component({
        name: 'VehicleDetail',
        computed: {
            ...mapGetters(['translate', 'market']),
        },
    })
], VehicleDetail);
export default VehicleDetail;
