import { __decorate } from "tslib";
import Vue from 'vue';
import { MODULES } from '@/store/modules';
import { mapActions, mapGetters } from 'vuex';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { initAssetServer } from '@/__webpack_public_path__';
import store from '@/store';
import { APP_BRAND, APP_STATUS, appendScript, APPLICATION_ID, APPLICATION_READY_EVENT_ID } from '@/utils';
import { getAemEnvironmentVariables } from '@/utils/aemEnvironments';
import { getAemComponentData } from '@/utils/dialogParameters';
import ErrorBase from '@/components/error/error.vue';
import GridColumn from '@/components/grid-column/grid-column.vue';
import LoadingSpinner from '@/components/loading-spinner/loading-spinner.vue';
import MqIndicator from '@/components/mq-indicator/mq-indicator.vue';
import '@/assets/main.scss';
import VehicleTile from '@/views/VehicleTile.vue';
import { tracking } from '@/utils/TrackingManager';
initAssetServer();
Vue.component('grid-column', GridColumn);
let App = class App extends Vue {
    initialisationProperties;
    disableFullscreenViews;
    componentId;
    initialiseApplication;
    setAppStatus;
    appStatus;
    environment;
    disableVehicleSelectorFullscreen;
    intersectionObserver;
    /**
     * Show error component whenever the app is running in development env
     * and app status is set to error
     */
    get shouldShowError() {
        return this.environment === 'development' && this.appStatus === APP_STATUS.ERROR;
    }
    getEnvironment(stage) {
        const ocapiEnv = {
            dev: 'development',
            int: 'test',
            test: 'test',
        };
        const stageEnv = stage ? ocapiEnv[stage.toLowerCase()] : '';
        return stageEnv || 'production';
    }
    get isLocal() {
        return window.location.hostname === 'localhost';
    }
    isInitialisedByProps() {
        return !!this.initialisationProperties;
    }
    attachIntersectionObserver() {
        const options = {
            threshold: 0.25,
        };
        const applicationElement = this.$refs.application;
        if (window.IntersectionObserver) {
            this.intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        tracking.initialize();
                        this.intersectionObserver.unobserve(applicationElement);
                    }
                });
            }, options);
            this.intersectionObserver.observe(applicationElement);
        }
        else {
            tracking.initialize();
        }
    }
    async getInitialisationData() {
        if (this.isInitialisedByProps()) {
            const { market, language, environment, applicationType, applicationBrand = APP_BRAND.MERCEDES_BENZ_CARS, typeId, modelSeries, subBrand, subBrands, engineConcept, customConfiguration, defaultFinancingProduct, defaultCustomerType, } = this.initialisationProperties;
            return {
                language,
                market,
                locale: `${language}_${market}`,
                localeFormat: `${language}-${market}`,
                appType: applicationType,
                applicationBrand,
                defaultFinancingProduct,
                defaultCustomerType,
                customConfiguration,
                environment,
                vehicle: {
                    typeId,
                    modelSeries,
                    subBrand,
                    subBrands,
                    engineConcept,
                },
            };
        }
        try {
            let environmentData = {};
            if (this.isLocal) {
                const file = await fetch(`aem-environment-data.json`);
                environmentData = await file.json();
            }
            else {
                environmentData = await getAemEnvironmentVariables();
            }
            console.log('environmentData', environmentData, this.isLocal);
            let aemComponentData = {};
            if (this.isLocal) {
                const file = await fetch(`aem-component-data.json`);
                aemComponentData = await file.json();
            }
            else {
                aemComponentData = await getAemComponentData(this.componentId);
            }
            console.log('aemComponentData', aemComponentData, this.isLocal);
            const dialogData = aemComponentData.payload;
            const hasMissingAemParameter = !Object.keys(environmentData).length || !Object.keys(dialogData).length;
            if (hasMissingAemParameter) {
                console.log('fcis_environmentData::', environmentData);
                console.log('fcis_aemComponentData::', aemComponentData);
                throw new Error();
            }
            const { country, language, stage, vehicleData } = environmentData;
            const { applicationType, applicationBrand = APP_BRAND.MERCEDES_BENZ_CARS, typeId, defaultFinancingProduct, defaultCustomerType, } = dialogData;
            return {
                language,
                market: country,
                locale: `${language}_${country}`,
                localeFormat: `${language}-${country}`,
                appType: applicationType,
                applicationBrand,
                defaultFinancingProduct,
                defaultCustomerType,
                environment: this.getEnvironment(stage),
                vehicle: {
                    typeId,
                    modelSeries: vehicleData?.modelSeries,
                    subBrand: vehicleData?.subBrand,
                    subBrands: vehicleData?.subBrands,
                    engineConcept: vehicleData?.engineConcept,
                },
            };
        }
        catch (e) {
            this.setAppStatus(APP_STATUS.ERROR, { message: 'MARKET_UNAVAILABLE' });
            return null;
        }
    }
    async onPropsChange() {
        await this.initialiseApp();
    }
    onDisableFullscreenViewsChange() {
        if (this.disableFullscreenViews) {
            this.disableVehicleSelectorFullscreen(this.disableFullscreenViews);
        }
    }
    async initialiseApp() {
        try {
            const initialisationData = await this.getInitialisationData();
            if (initialisationData) {
                this.initialiseApplication(initialisationData);
            }
        }
        catch (e) {
            this.setAppStatus(APP_STATUS.ERROR, { message: 'MARKET_UNAVAILABLE' });
        }
    }
    mounted() {
        const { VUE_APP_AEM_FORMS_ENDPOINT = '' } = process.env;
        if (VUE_APP_AEM_FORMS_ENDPOINT) {
            appendScript(VUE_APP_AEM_FORMS_ENDPOINT);
        }
        document.body.dispatchEvent(new CustomEvent(APPLICATION_READY_EVENT_ID));
        window.sessionStorage.removeItem('swsp:FCIS_DATA');
        this.attachIntersectionObserver();
        this.initialiseApp();
    }
};
__decorate([
    Prop()
], App.prototype, "initialisationProperties", void 0);
__decorate([
    Prop()
], App.prototype, "disableFullscreenViews", void 0);
__decorate([
    Prop({ default: 'fcis-owdc', required: false, type: String })
], App.prototype, "componentId", void 0);
__decorate([
    Watch('initialisationProperties')
], App.prototype, "onPropsChange", null);
__decorate([
    Watch('disableFullscreenViews', { immediate: true })
], App.prototype, "onDisableFullscreenViewsChange", null);
App = __decorate([
    Component({
        name: 'App',
        store,
        components: { VehicleTile, ErrorBase, LoadingSpinner, MqIndicator },
        computed: {
            ...mapGetters(['appStatus', 'environment', 'appErrorCode']),
        },
        methods: {
            ...mapActions(['initialiseApplication', 'setAppStatus']),
            ...mapActions(MODULES.VEHICLE_SELECTOR, { disableVehicleSelectorFullscreen: 'disableFullscreen' }),
        },
        data: () => {
            return {
                APPLICATION_ID: APPLICATION_ID,
                APP_STATUS: APP_STATUS,
                IS_LOCAL: process.env.VUE_APP_VMDS_DEV,
            };
        },
    })
], App);
export default App;
