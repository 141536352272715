import { isElectricCCS, isHybridCCS } from '@/utils';
const BASE_URL = {
    test: 'https://test.api.oneweb.mercedes-benz.com/fcis-calculation-api/v1',
    production: 'https://api.oneweb.mercedes-benz.com/fcis-calculation-api/v1',
    development: 'https://dev.api.oneweb.mercedes-benz.com/fcis-calculation-api/v1',
};
class CarConfigurationService {
    market = '';
    language = '';
    baseUrl = '';
    setConfig(market, language, environment) {
        this.market = market;
        this.language = language;
        const base = BASE_URL[environment] || BASE_URL['production'];
        this.baseUrl = `${base}`;
    }
    async fetchVehicleCosts(combinedElectricConsumption, combinedGasConsumption, combinedCo2Emission, engineCapacity, fuelType, engineConcept) {
        const urlPrefix = `${this.baseUrl}/vehicle/costs/envkv/${this.market}/${this.language}?`;
        let extraConsumptionParams = '';
        if (isElectricCCS(engineConcept)) {
            // Electric vehicle scenario
            extraConsumptionParams = `combinedElectricConsumption=${combinedElectricConsumption}`;
        }
        else if (isHybridCCS(engineConcept)) {
            // Hybrid vehicle scenario
            extraConsumptionParams = `combinedGasConsumption=${combinedGasConsumption}&combinedElectricConsumption=${combinedElectricConsumption}&engineCapacity=${engineCapacity}`;
        }
        else {
            // Combustion engine scenario
            extraConsumptionParams = `combinedGasConsumption=${combinedGasConsumption}&engineCapacity=${engineCapacity}`;
        }
        const url = `${urlPrefix}${extraConsumptionParams}&combinedCo2Emission=${combinedCo2Emission}&fuelType=${fuelType}&engineConcept=${engineConcept}`;
        const response = await fetch(url);
        const result = await response.json();
        return result;
    }
}
const carConfigurationService = new CarConfigurationService();
export default carConfigurationService;
