import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
let Toggle = class Toggle extends Vue {
    selectedInput = '';
    shouldBeAbleToChangeCustomer = false;
    cleoCustomerTypeInput;
    setCustomerTypeChange;
    mounted() {
        this.shouldBeAbleToChangeCustomer = this.cleoCustomerTypeInput.values.length > 1;
    }
    handleChange(e) {
        this.shouldBeAbleToChangeCustomer = this.cleoCustomerTypeInput.values.length > 1;
        const isOcapiSecondValueSelected = this.shouldBeAbleToChangeCustomer && this.cleoCustomerTypeInput.values[1].selected;
        this.selectedInput = isOcapiSecondValueSelected
            ? this.cleoCustomerTypeInput.values[0].value
            : this.cleoCustomerTypeInput.values[1].value;
        this.setCustomerTypeChange(this.selectedInput);
        e.stopPropagation();
    }
};
Toggle = __decorate([
    Component({
        name: 'Toggle',
        computed: {
            ...mapGetters(['cleoCustomerTypeInput']),
        },
        methods: {
            ...mapActions(['setCustomerTypeChange']),
        },
    })
], Toggle);
export default Toggle;
