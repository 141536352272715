import gql from 'graphql-tag';
export const singleVehicleEmissionConsumptionQuery = gql `
  query ($market: String!, $language: String!, $typeId: String!) {
    typeIdVehicle(market: $market, language: $language, typeId: $typeId) {
      technicalData {
        transmission {
          defaultTransmission
        }
        consumption {
          manual {
            city {
              individual
              min
              max
              unit
            }
            combined {
              individual
              min
              max
              unit
            }
            consumptionEquivalent {
              individual
              min
              max
              unit
            }
            electricCombined {
              individual
              min
              max
              unit
            }
            nedc {
              city {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              combined {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              country {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              electric {
                individual
                min
                max
                unit
              }
              weighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
            }
            overland {
              individual
              min
              max
              unit
            }
            wltp {
              chargeDeplete {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              chargeDepleteWeighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              chargeSustain {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              extraHigh {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              high {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              low {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              lowMid {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              medium {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              total {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              weighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
            }
          }
          automatic {
            city {
              individual
              min
              max
              unit
            }
            combined {
              individual
              min
              max
              unit
            }
            consumptionEquivalent {
              individual
              min
              max
              unit
            }
            electricCombined {
              individual
              min
              max
              unit
            }
            nedc {
              city {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              combined {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              country {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              electric {
                individual
                min
                max
                unit
              }
              weighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
            }
            overland {
              individual
              min
              max
              unit
            }
            wltp {
              chargeDeplete {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              chargeDepleteWeighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              chargeSustain {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              extraHigh {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              high {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              low {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              lowMid {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              medium {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              total {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              weighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
            }
          }
        }
        emissions {
          automatic {
            co2 {
              individual
              min
              max
              unit
            }
            co2AverageValue {
              unit
              value
            }
            co2TargetValue {
              unit
              value
            }
            emissionForFuelSupply {
              individual
              min
              max
              unit
            }
            emissionStandard
            energyEfficiencyClass
            energyEfficiencyVesBandSg
            hydrocarbon {
              unit
              value
            }
            nedc {
              city {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              combined {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              country {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              weighted {
                individual
                min
                max
                unit
              }
            }
            wltp {
              co2Class {
                individual
                min
                max
              }
              co2ClassWeighted {
                individual
                min
                max
              }
              chargeDeplete {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              chargeSustain {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              extraHigh {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              high {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              low {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              medium {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              total {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              weighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
            }
          }
          manual {
            co2 {
              individual
              min
              max
              unit
            }
            co2AverageValue {
              unit
              value
            }
            co2TargetValue {
              unit
              value
            }
            emissionForFuelSupply {
              individual
              min
              max
              unit
            }
            emissionStandard
            energyEfficiencyClass
            energyEfficiencyVesBandSg
            hydrocarbon {
              unit
              value
            }
            nedc {
              city {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              combined {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              country {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              weighted {
                individual
                min
                max
                unit
              }
            }
            wltp {
              co2Class {
                individual
                min
                max
              }
              co2ClassWeighted {
                individual
                min
                max
              }
              chargeDeplete {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              chargeSustain {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              extraHigh {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              high {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              low {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              medium {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              total {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
              weighted {
                primaryEngine {
                  individual
                  min
                  max
                  unit
                }
                secondaryEngine {
                  individual
                  min
                  max
                  unit
                }
              }
            }
          }
        }
        engine {
          electricEngine {
            wltpRange {
              automatic {
                total {
                  all {
                    max
                    individual
                    unit
                  }
                  equivalentAll {
                    individual
                    unit
                  }
                }
                lowMid {
                  all {
                    max
                    individual
                    unit
                  }
                  equivalentAll {
                    individual
                    unit
                  }
                }
              }
              manual {
                total {
                  all {
                    max
                    individual
                    unit
                  }
                }
                lowMid {
                  all {
                    max
                    individual
                    unit
                  }
                }
              }
            }
          }
          combustionEngine {
            capacity {
              value
            }
          }
          engineConcept
          fuelType
        }
        body {
          weights {
            automatic {
              weightMax {
                value
              }
            }
            manual {
              weightMax {
                value
              }
            }
          }
          seats
        }
      }
    }
  }
`;
