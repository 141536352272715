import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VehicleSelectorBodyType = class VehicleSelectorBodyType extends Vue {
    id;
    name;
    imageSrc;
    onClick() {
        this.$emit('click');
    }
};
__decorate([
    Prop({ required: true })
], VehicleSelectorBodyType.prototype, "id", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorBodyType.prototype, "name", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorBodyType.prototype, "imageSrc", void 0);
VehicleSelectorBodyType = __decorate([
    Component({ name: 'VehicleSelectorBodyType' })
], VehicleSelectorBodyType);
export default VehicleSelectorBodyType;
