const meta = require('./../oneweb.js');
const NAME = `${meta.aemComponentConfig.productPrefix}-${meta.aemComponentConfig.tagNameWithoutPrefix}`;
/**
 * vue needs to know the asset uri url to download the chunks
 */
export const initAssetServer = () => {
    if (window.aemNamespace) {
        // AEM MOCK SERVER
        if (window.aemNamespace.environmentVariables[NAME]) {
            __webpack_public_path__ = `${window.aemNamespace.environmentVariables[NAME].__webpack_public_path__}`;
        }
        // AEM servers
        if (window.aemNamespace.environmentVariables.assetUri &&
            !window.aemNamespace.environmentVariables[NAME]) {
            __webpack_public_path__ = `${window.aemNamespace.environmentVariables.assetUri}/${NAME}/${NAME}-${meta.version}/`;
        }
        const stage = window.aemNamespace.environmentVariables.stage;
        console.log('stage', stage);
        if (stage !== 'prod') {
            console.log('__webpack_public_path__ ' + NAME, __webpack_public_path__);
        }
    }
};
