import { initializeComponentRegistry } from '@owc/component-registry-connection';
/**
 * Read AEM dialogs
 * @param componentId e.g. dh-io-fcis, fcis-owdc
 */
export async function getAemComponentData(componentId) {
    return new Promise((resolve) => {
        const store = initializeComponentRegistry();
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const listener = (state) => {
            resolve(state);
            store.unsubscribe(componentId, listener);
        };
        store.subscribe(componentId, listener);
    });
}
