import gql from 'graphql-tag';
export const multipleClassQuery = gql `
  query ($market: String!, $language: String!, $filter: VehiclesFilterInput!) {
    classBodytypeVehicles(market: $market, language: $language, filter: $filter) {
      className
      classId
      bodytypes {
        bodytypeId
        bodytypeName
        models {
          modelSeries
          vehicles {
            baumuster
            bodytypeId
            bodytypeName
            changeYear
            classId
            className
            financeData {
              currency
              discountPrices {
                automatic {
                  discounts {
                    amount
                    netAmount
                    name
                    type
                  }
                  price
                  taxes {
                    amount
                    baseAmount
                    name
                    rate
                  }
                }
                manual {
                  discounts {
                    amount
                    netAmount
                    name
                    type
                  }
                  price
                  netPrice
                  taxes {
                    amount
                    baseAmount
                    name
                    rate
                  }
                }
              }
              prices {
                automatic {
                  netPrice
                  price
                  taxes {
                    amount
                    baseAmount
                    name
                    rate
                  }
                }
                manual {
                  netPrice
                  price
                  taxes {
                    amount
                    baseAmount
                    name
                    rate
                  }
                }
              }
            }
            images {
              cutouts {
                medium
              }
              stage
            }
            modelSeriesFacelift
            modelYear
            name
            nationalSalesType
            productGroup
            subBrands
            lifeCycle
            technicalData {
              engine {
                electricEngine {
                  powerInKw {
                    unit
                    value
                  }
                  wltpRange {
                    automatic {
                      total {
                        all {
                          individual
                        }
                        equivalentAll {
                          individual
                        }
                      }
                    }
                  }
                }
                combustionEngine {
                  powerInKw {
                    unit
                    value
                  }
                }
                engineConcept
                fuelType
              }
              emissions {
                automatic {
                  wltp {
                    weighted {
                      primaryEngine {
                        individual
                      }
                    }
                    total {
                      primaryEngine {
                        individual
                      }
                    }
                  }
                }
              }
              consumption {
                automatic {
                  wltp {
                    weighted {
                      primaryEngine {
                        individual
                      }
                    }
                    total {
                      primaryEngine {
                        individual
                      }
                    }
                  }
                }
              }
              transmission {
                defaultTransmission
              }
            }
            typeId
          }
        }
      }
    }
  }
`;
