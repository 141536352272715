import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { getValue } from '@/components/consumption/utils';
let ConsumptionComponent = class ConsumptionComponent extends Vue {
    wltpItems;
    currentEmissions;
    currentConsumption;
    currentEngine;
    currentEngineConcept;
    getValueForItem(item) {
        return (getValue(item, {
            emissions: this.currentEmissions,
            consumption: this.currentConsumption,
            engine: this.currentEngine,
        }) || '');
    }
    get entries() {
        return this.wltpItems.filter((item) => {
            return item.engines.includes(this.currentEngineConcept);
        });
    }
};
ConsumptionComponent = __decorate([
    Component({
        name: 'ConsumptionComponent',
        computed: {
            ...mapGetters([
                'wltpItems',
                'currentEngine',
                'currentEmissions',
                'currentConsumption',
                'currentEngineConcept',
                'translate',
            ]),
        },
    })
], ConsumptionComponent);
export default ConsumptionComponent;
