import { initializeStore } from '@seamless/store';
import { addConnectionAemEnvironmentConnection, connectionName, } from '@owc/connection-aem-environment';
export const getAemEnvironmentVariables = () => new Promise((resolve) => {
    const store = initializeStore();
    addConnectionAemEnvironmentConnection().finally(() => {
        store.once(connectionName, (state) => {
            resolve(state);
        });
    });
});
