import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { getMainFuelType } from '@/store/modules/vehicleSelector/utils';
import { isHybrid } from '@/utils';
import { FuelType } from '@/store/modules/vmds/types';
import { getPlsValue } from '@/components/consumption/utils';
let PlsPriceUi = class PlsPriceUi extends Vue {
    locale;
    vehicle;
    loading;
    get configProduct() {
        return {
            productType: 'CONFIGURATION',
            vehicleId: `${this.locale}__${this.vehicle.baumuster}`,
            productGroup: this.vehicle.productGroup === 'VAN' ? 'VAN' : 'PC',
            basePriceAmount: this.vehicle.netPrice,
            totalOptionPriceAmount: 0,
            configurationPriceAmount: this.vehicle.netPrice,
            technicalData: {
                co2EmissionTotalCombined: this.getPlsTechnicalData['co2EmissionTotalCombined'],
                fuelConsumptionTotalCombined: this.getPlsTechnicalData['fuelConsumptionTotalCombined'],
                engine: {
                    type: this.plsFuelType,
                    driveCycle: {
                        wltp: {
                            emission: {
                                total: this.getPlsTechnicalData['engine.driveCycle.wltp.emission.total'],
                                weighted: this.getPlsTechnicalData['engine.driveCycle.wltp.emission.weighted'],
                            },
                            range: {
                                totalAll: this.getPlsTechnicalData['engine.driveCycle.range.totalAll'],
                                totalEquivalentAll: this.getPlsTechnicalData['engine.driveCycle.range.totalEquivalentAll'],
                            },
                        },
                        range: {
                            totalAll: this.getPlsTechnicalData['engine.driveCycle.range.totalAll'],
                            totalEquivalentAll: this.getPlsTechnicalData['engine.driveCycle.range.totalEquivalentAll'],
                        },
                    },
                },
            },
            dcpProductType: {
                name: '',
            },
        };
    }
    get plsLocal() {
        return this.locale.replace('_', '-');
    }
    get plsFuelType() {
        if (this.isHybrid) {
            return 'HYBRID';
        }
        const mainFuelType = getMainFuelType(this.vehicle.fuelTypes);
        switch (mainFuelType) {
            case FuelType.ELECTRIC:
                return 'ELECTRIC';
            default:
                return 'COMBUSTOR';
        }
    }
    get getPlsTechnicalData() {
        const mappings = {
            co2EmissionTotalCombined: 'emissions.automatic.wltp.weighted.primaryEngine.individual',
            fuelConsumptionTotalCombined: this.isHybrid
                ? 'consumption.automatic.wltp.weighted.primaryEngine.individual'
                : 'consumption.automatic.wltp.total.primaryEngine.individual',
            'engine.driveCycle.wltp.emission.total': 'emissions.automatic.wltp.total.primaryEngine.individual',
            'engine.driveCycle.wltp.emission.weighted': 'emissions.automatic.wltp.weighted.primaryEngine.individual',
            'engine.driveCycle.range.totalAll': 'engine.electricEngine.wltpRange.automatic.total.all.individual',
            'engine.driveCycle.range.totalEquivalentAll': 'engine.electricEngine.wltpRange.automatic.total.equivalentAll.individual',
        };
        const plsTechnicalData = Object.keys(mappings).reduce((aggregatedTechnicalData, key) => ({
            ...aggregatedTechnicalData,
            [key]: getPlsValue(mappings[key], this.vehicle.technicalData) || 0,
        }), {});
        return plsTechnicalData;
    }
    get isHybrid() {
        return isHybrid(this.vehicle.engineConcept);
    }
};
__decorate([
    Prop({ required: true })
], PlsPriceUi.prototype, "vehicle", void 0);
__decorate([
    Prop({ default: 'lazy' })
], PlsPriceUi.prototype, "loading", void 0);
PlsPriceUi = __decorate([
    Component({
        name: 'PlsPriceUi',
        computed: {
            ...mapGetters(['locale']),
        },
    })
], PlsPriceUi);
export default PlsPriceUi;
