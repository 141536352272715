import { __decorate } from "tslib";
import { tracking } from '../../utils/TrackingManager';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
let BaseButton = class BaseButton extends Vue {
    /**
     * Props
     */
    name;
    disabled;
    isExpanded;
    isTrackable;
    url;
    target;
    size;
    type;
    nativeType;
    /**
     * State properties
     */
    /**
     * Methods
     */
    async onClick(event) {
        const isRedirect = this.url && !this.url.startsWith('#');
        if (this.isTrackable && this.name) {
            const trackingTimeoutPromise = new Promise((resolve) => {
                setTimeout(resolve, 3000);
            });
            // delay redirects to handle tracking
            if (isRedirect) {
                event.preventDefault();
            }
            // give tracking up to 3 additional seconds, then continue
            await Promise.any([trackingTimeoutPromise, tracking.action(this.name)]);
            if (isRedirect) {
                location.href = this.url;
            }
        }
    }
};
__decorate([
    Prop({ required: true })
], BaseButton.prototype, "name", void 0);
__decorate([
    Prop({ default: false })
], BaseButton.prototype, "disabled", void 0);
__decorate([
    Prop({ default: true })
], BaseButton.prototype, "isExpanded", void 0);
__decorate([
    Prop({ default: true })
], BaseButton.prototype, "isTrackable", void 0);
__decorate([
    Prop()
], BaseButton.prototype, "url", void 0);
__decorate([
    Prop()
], BaseButton.prototype, "target", void 0);
__decorate([
    Prop({ default: 'large' })
], BaseButton.prototype, "size", void 0);
__decorate([
    Prop({ default: 'primary' })
], BaseButton.prototype, "type", void 0);
__decorate([
    Prop({ default: 'button' })
], BaseButton.prototype, "nativeType", void 0);
__decorate([
    Emit('click')
], BaseButton.prototype, "onClick", null);
BaseButton = __decorate([
    Component({ name: 'BaseButton' })
], BaseButton);
export default BaseButton;
