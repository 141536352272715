import { __decorate } from "tslib";
import { mapGetters } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MODULES } from '@/store/modules';
import BaseButton from '@/components/base-button/base-button.vue';
let WLTPModal = class WLTPModal extends Vue {
    /**
     * Props
     */
    message;
    title;
    /**
     * State properties
     */
    selectedVehicle;
    extraContent = false;
    emission;
    pertrolType;
    valuesList;
    modalListenerOnClose;
    /**
     * Lifecylce methods
     */
    beforeMount() {
        this.emission = this.selectedVehicle.emission;
        this.pertrolType = 'MANUAL' in this.emission.co2EmissionCombinedMax ? 'MANUAL' : 'AUTOMATIC';
    }
    /**
     * Methods
     */
    toggleExtraContentModal() {
        this.extraContent = !this.extraContent;
    }
    /**
     * Computed properties
     */
    get getModalTitle() {
        return !this.extraContent ? 'Verbrauch und Emissionen' : 'Technische Daten';
    }
};
__decorate([
    Prop()
], WLTPModal.prototype, "message", void 0);
__decorate([
    Prop()
], WLTPModal.prototype, "title", void 0);
WLTPModal = __decorate([
    Component({
        name: 'WLTPModal',
        components: { BaseButton },
        computed: {
            ...mapGetters(MODULES.VEHICLE_SELECTOR, ['selectedVehicle']),
        },
    })
], WLTPModal);
export default WLTPModal;
