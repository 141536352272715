import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
let AemFormWrapper = class AemFormWrapper extends Vue {
};
AemFormWrapper = __decorate([
    Component({
        name: 'AemFormWrapper',
        computed: {
            ...mapGetters(['market', 'locale', 'contactFormItems', 'aemFormURL']),
        },
    })
], AemFormWrapper);
export default AemFormWrapper;
