import { render, staticRenderFns } from "./VehicleTile.vue?vue&type=template&id=c0ba9830&"
import script from "./VehicleTile.vue?vue&type=script&lang=ts&"
export * from "./VehicleTile.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./VehicleTile.vue?vue&type=style&index=0&id=c0ba9830&prod&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports