import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let GridColumn = class GridColumn extends Vue {
    mq1;
    offsetMq1;
    mq2;
    offsetMq2;
    mq3;
    offsetMq3;
    mq4;
    offsetMq4;
    mq5;
    offsetMq5;
    mq6;
    offsetMq6;
    get classes() {
        let _classes = '';
        const offset = 'offset-mq';
        const prefix = 'wb-grid-col';
        const props = Object.keys(this.$props);
        props.forEach((prop) => {
            if (!this.$props[prop]) {
                return undefined;
            }
            const isOffsetMq = prop.indexOf('offsetMq') > -1;
            const mq = prop.charAt(prop.length - 1);
            // Concatenate a chain of classes. E.g.: wb-grid-col-mq1-10
            _classes += isOffsetMq
                ? `${prefix}-${offset}${mq}-${this.$props[prop]} `
                : `${prefix}-mq${mq}-${this.$props[prop]} `;
        });
        return _classes;
    }
};
__decorate([
    Prop()
], GridColumn.prototype, "mq1", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "offsetMq1", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "mq2", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "offsetMq2", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "mq3", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "offsetMq3", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "mq4", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "offsetMq4", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "mq5", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "offsetMq5", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "mq6", void 0);
__decorate([
    Prop()
], GridColumn.prototype, "offsetMq6", void 0);
GridColumn = __decorate([
    Component({ name: 'GridColumn' })
], GridColumn);
export default GridColumn;
