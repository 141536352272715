import { BUTTON_TYPE } from '@/utils';
import deeplinkService, { supportedButtons } from '@/services/DeeplinkService';
import { tracking } from '@/utils/TrackingManager';
const sortButtonsByClassification = (a, b) => {
    if (a.classification === b.classification) {
        return 0;
    }
    else if (a.classification === BUTTON_TYPE.CALL_TO_ACTION && b.classification === BUTTON_TYPE.DEFAULT) {
        return 1;
    }
    return -1;
};
export const defaultState = {
    title: '',
    quoteData: [],
    disclaimers: [],
    buttons: [],
    deeplink: {
        items: [],
        typeId: '',
    },
    contactFormItems: {},
    legalBannerImageUrl: '',
    legalBannerText: '',
    cleoCustomerTypeInput: null,
    displayCustomerTypeToggle: false,
    customerTypeToggleValue: '',
};
export const mutations = {
    SET_TITLE: (state, title) => {
        state.title = title;
    },
    SET_QUOTE_DATA: (state, data) => {
        state.quoteData = [...data];
    },
    SET_BUTTONS: (state, data) => {
        state.buttons = [...data];
    },
    SET_DEEPLINK_ITEMS: (state, data) => {
        state.deeplink.items = [...data];
    },
    SET_DEEPLINK_TYPE_ID: (state, typeId) => {
        state.deeplink.typeId = typeId;
    },
    SET_LEGAL_BANNER_IMAGE: (state, data) => {
        state.legalBannerImageUrl = data;
    },
    SET_LEGAL_BANNER_TEXT: (state, data) => {
        state.legalBannerText = data;
    },
    SET_DISCLAIMERS_DATA: (state, data) => {
        state.disclaimers = data;
    },
    SET_CONTACT_FORM_DATA: (state, payload) => {
        state.contactFormItems = payload;
    },
    SET_CUSTOMER_TYPE_INPUT: (state, payload) => {
        const oldCustomerType = state.cleoCustomerTypeInput?.value?.value;
        const newCustomerType = payload?.value?.value;
        const trackingName = (newCustomerType ?? '').trim().replaceAll(' ', '_');
        if (oldCustomerType && newCustomerType !== oldCustomerType) {
            tracking.update(`calculation_clientType.${trackingName}`);
        }
        state.cleoCustomerTypeInput = payload;
    },
    SET_DISPLAY_CUSTOMER_TYPE_TOGGLE: (state, payload) => {
        state.displayCustomerTypeToggle = payload;
    },
    SET_CUSTOMER_TYPE_CHANGE: (state, payload) => {
        state.customerTypeToggleValue = payload;
    },
};
export const actions = {
    setDisclaimers({ commit }, payload) {
        commit('SET_DISCLAIMERS_DATA', payload);
    },
    setTitle({ commit }, title) {
        commit('SET_TITLE', title);
    },
    async setDeeplinkItems({ commit, state }, typeId) {
        if (typeId !== state.deeplink.typeId) {
            const result = await deeplinkService.fetchLinkForTypeId(typeId);
            commit('SET_DEEPLINK_ITEMS', result);
            commit('SET_DEEPLINK_TYPE_ID', typeId);
        }
    },
    setLegalBannerImage({ commit }, url) {
        commit('SET_LEGAL_BANNER_IMAGE', url);
    },
    setLegalBannerText({ commit }, text) {
        commit('SET_LEGAL_BANNER_TEXT', text);
    },
    async setButtons({ dispatch, commit, rootGetters, state }, ocapiButtons) {
        await dispatch('setDeeplinkItems', rootGetters.currentVehicle.typeId);
        const deepLinks = state.deeplink.items || [];
        /* remove black listed buttons  */
        const removeBlackListedButtons = (button) => {
            return !rootGetters.configurations.outputs.buttonsBlackList?.find((item) => item.id === button.id && item.class === rootGetters.currentVehicle.classId);
        };
        const deepLinksFiltered = deepLinks?.filter((button) => {
            return removeBlackListedButtons(button);
        });
        // Links without support from deepLinks service
        // Also, filter out buttons without IDs as they cannot be validated
        const unsuportedLinks = ocapiButtons
            .filter((item) => item.id)
            .filter((item) => !supportedButtons.includes(item.id));
        // Links supported by deepLinks service
        const supportedLinks = ocapiButtons.filter((item) => supportedButtons.includes(item.id));
        // Link with results from deepLink service
        const deeplinkItems = supportedLinks
            .map((button) => {
            const deeplink = deepLinksFiltered.find((link) => link.id === button.id);
            return {
                classification: button.classification,
                ...deeplink,
            };
        })
            .filter((item) => item.url);
        const configurationButtons = rootGetters.configurations.outputs.buttons || [];
        const translatedButtons = configurationButtons.map((button) => {
            return {
                ...button,
                label: rootGetters.translate(button.label),
            };
        });
        const allButtons = [...translatedButtons, ...deeplinkItems, ...unsuportedLinks];
        allButtons.sort(sortButtonsByClassification);
        commit('SET_BUTTONS', allButtons);
    },
    setQuoteData({ commit }, payload) {
        commit('SET_QUOTE_DATA', payload);
    },
    setContactFormItems({ commit }, payload) {
        commit('SET_CONTACT_FORM_DATA', {
            ...defaultState.contactFormItems,
            ...payload,
        });
    },
    setCustomerTypeInput({ commit }, payload) {
        commit('SET_CUSTOMER_TYPE_INPUT', payload);
    },
    shouldDisplayCustomerTypeToggle({ commit }, payload) {
        commit('SET_DISPLAY_CUSTOMER_TYPE_TOGGLE', payload);
    },
    setCustomerTypeChange({ commit }, payload) {
        commit('SET_CUSTOMER_TYPE_CHANGE', payload);
    },
};
export const getters = {
    title: (state) => state.title,
    quoteData: (state) => state.quoteData,
    buttons: (state) => state.buttons,
    disclaimers: (state) => state.disclaimers,
    contactFormItems: (state) => state.contactFormItems,
    legalBannerImageUrl: (state) => state.legalBannerImageUrl,
    legalBannerText: (state) => state.legalBannerText,
    cleoCustomerTypeInput: (state) => state.cleoCustomerTypeInput,
    displayCustomerTypeToggle: (state) => state.displayCustomerTypeToggle,
    customerTypeToggleValue: (state) => state.customerTypeToggleValue,
};
export const OutputsModule = {
    state: defaultState,
    mutations,
    actions,
    getters,
};
