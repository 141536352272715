import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let LoadingSpinner = class LoadingSpinner extends Vue {
    /**
     * Props
     */
    width;
    height;
};
__decorate([
    Prop({ default: 50 })
], LoadingSpinner.prototype, "width", void 0);
__decorate([
    Prop({ default: 50 })
], LoadingSpinner.prototype, "height", void 0);
LoadingSpinner = __decorate([
    Component({ name: 'LoadingSpinner' })
], LoadingSpinner);
export default LoadingSpinner;
