import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let Modal = class Modal extends Vue {
    // Props
    mode;
    title;
    // State
    modalListenerOnClose;
    mounted() {
        this.modalListenerOnClose = this.$el.addEventListener('wbclose', (event) => {
            this.$emit('close', event);
        }, false);
    }
    beforeDestroy() {
        this.$el.removeEventListener('wbclose', this.modalListenerOnClose, false);
    }
};
__decorate([
    Prop({ default: 'info' })
], Modal.prototype, "mode", void 0);
__decorate([
    Prop({ default: ' ' })
], Modal.prototype, "title", void 0);
Modal = __decorate([
    Component({ name: 'Modal' })
], Modal);
export default Modal;
