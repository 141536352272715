import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { singleClassQuery } from '@/graphql/singleClassQuery';
import { multipleClassQuery } from '@/graphql/multipleClassQuery';
import { singleVehicleEmissionConsumptionQuery } from '@/graphql/singleVehicleEmissionConsumptionQuery';
let apolloClient;
let clientUrl = '';
export function useApolloClient(uri) {
    if (apolloClient && uri === clientUrl) {
        return apolloClient;
    }
    apolloClient = new ApolloClient({
        uri,
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });
    clientUrl = uri;
    return apolloClient;
}
export { singleClassQuery, multipleClassQuery, singleVehicleEmissionConsumptionQuery };
