import gql from 'graphql-tag';
export const singleClassQuery = gql `
  query ($market: String!, $language: String!, $modelSeries: String!, $filter: ModelSeriesVehiclesFilterInput!) {
    modelSeriesVehicles(market: $market, language: $language, modelSeries: $modelSeries, filter: $filter) {
      classData {
        id
        name
      }
      bodytypeData {
        id
        name
      }
      modelSeries
      vehicles {
        baumuster
        bodytypeId
        bodytypeName
        changeYear
        classId
        className
        financeData {
          currency
          discountPrices {
            automatic {
              discounts {
                amount
                netAmount
                name
                type
              }
              price
              taxes {
                amount
                baseAmount
                name
                rate
              }
            }
            manual {
              discounts {
                amount
                netAmount
                name
                type
              }
              price
              netPrice
              taxes {
                amount
                baseAmount
                name
                rate
              }
            }
          }
          prices {
            automatic {
              netPrice
              price
              taxes {
                amount
                baseAmount
                name
                rate
              }
            }
            manual {
              netPrice
              price
              taxes {
                amount
                baseAmount
                name
                rate
              }
            }
          }
        }
        images {
          cutouts {
            medium
          }
          stage
        }
        modelSeriesFacelift
        modelYear
        name
        nationalSalesType
        subBrands
        lifeCycle
        technicalData {
          engine {
            electricEngine {
              powerInKw {
                unit
                value
              }
              wltpRange {
                automatic {
                  total {
                    all {
                      individual
                    }
                    equivalentAll {
                      individual
                    }
                  }
                }
              }
            }
            combustionEngine {
              powerInKw {
                unit
                value
              }
              capacity {
                value
              }
            }
            engineConcept
            fuelType
          }
          emissions {
            automatic {
              wltp {
                weighted {
                  primaryEngine {
                    individual
                  }
                }
                total {
                  primaryEngine {
                    individual
                  }
                }
              }
            }
          }
          consumption {
            automatic {
              wltp {
                weighted {
                  primaryEngine {
                    individual
                  }
                }
                total {
                  primaryEngine {
                    individual
                  }
                }
              }
            }
          }
          transmission {
            defaultTransmission
          }
        }
        typeId
      }
    }
  }
`;
