import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VehicleSelectorModel = class VehicleSelectorModel extends Vue {
    id;
    name;
    onClick() {
        this.$emit('click');
    }
};
__decorate([
    Prop({ required: true })
], VehicleSelectorModel.prototype, "id", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorModel.prototype, "name", void 0);
VehicleSelectorModel = __decorate([
    Component({ name: 'VehicleSelectorModel' })
], VehicleSelectorModel);
export default VehicleSelectorModel;
