import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import VehicleCard from '@/components/vehicle-card/vehicle-card.vue';
import CleopatraWrapper from '@/components/cleopatra-wrapper/cleopatra-wrapper.vue';
import { APP_STATUS, CONTACT_FORM_BUTTON_ID, OCAPI_BUTTON_TYPE } from '@/utils';
import VehicleSelectorBody from '@/components/vehicle-selector-body/vehicle-selector-body.vue';
import { MODULES } from '@/store/modules';
import LegalBanner from '@/components/legal-banner/legal-banner.vue';
import Footnote from '@/components/footnote/footnote.vue';
import VehicleTileFooter from '@/components/vehicle-tile-footer/vehicle-tile-footer.vue';
import Modal from '@/components/modal/modal.vue';
import AemFormWrapper from '@/components/aem-form-wrapper/aem-form-wrapper.vue';
import Toggle from '@/components/toggle/toggle.vue';
import { MediaQueryService } from '@workbench/core';
const myMediaQueryService = new MediaQueryService(window);
let VehicleTile = class VehicleTile extends Vue {
    legalBannerText;
    legalBannerImageUrl;
    disclaimers;
    isContactFormVisible = false;
    currentMQ = myMediaQueryService.getCurrentMediaQuery();
    onResize(e) {
        this.currentMQ = e.detail.current;
    }
    get shouldDisplayMobile() {
        return this.currentMQ === 'mq1' || this.currentMQ === 'mq2' || this.currentMQ === 'mq3';
    }
    onWindowResize() {
        // to observe media query changes across time
        window.addEventListener('wbresize', this.onResize);
        // pass in `true` if you are interested in the media query in the moment of calling the function
        myMediaQueryService.observe(true);
    }
    mounted() {
        this.onWindowResize();
    }
    beforeDestroy() {
        window.removeEventListener('wbresize', this.onResize);
        myMediaQueryService.disconnect();
    }
    get shouldDisplayLegalBanner() {
        return this.legalBannerImageUrl || this.legalBannerText;
    }
    get shouldDisplayDisclaimers() {
        return !!this.disclaimers;
    }
    toggleContactFormModal() {
        this.isContactFormVisible = !this.isContactFormVisible;
    }
    handleButtonClick(buttonId) {
        if (buttonId === CONTACT_FORM_BUTTON_ID || buttonId === OCAPI_BUTTON_TYPE.CAR_CONFIGURATOR) {
            const widget = this.$refs.cleopatraWidget;
            widget.generateFinanceCode(buttonId);
        }
    }
};
VehicleTile = __decorate([
    Component({
        name: 'VehicleTile',
        components: {
            Footnote,
            LegalBanner,
            AemFormWrapper,
            Modal,
            VehicleTileFooter,
            VehicleSelectorBody,
            CleopatraWrapper,
            VehicleCard,
            Toggle,
        },
        computed: {
            ...mapGetters(MODULES.VEHICLE_SELECTOR, ['vehicleSelectorIsVisible']),
            ...mapGetters([
                'appStatus',
                'title',
                'legalBannerImageUrl',
                'legalBannerText',
                'disclaimers',
                'displayCustomerTypeToggle',
            ]),
        },
        data: () => {
            return {
                APP_STATUS: APP_STATUS,
            };
        },
    })
], VehicleTile);
export default VehicleTile;
