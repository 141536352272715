import { __decorate } from "tslib";
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { tracking } from '@/utils/TrackingManager';
let LegalBanner = class LegalBanner extends Vue {
    isOpen = true;
    handleClick() {
        if (this.isOpen) {
            tracking.action('dismiss', 'feature', 'legal_disclaimer_banner');
        }
        this.isOpen = !this.isOpen;
    }
};
LegalBanner = __decorate([
    Component({
        name: 'LegalBanner',
        computed: {
            ...mapGetters(['legalBannerImageUrl', 'legalBannerText']),
        },
    })
], LegalBanner);
export default LegalBanner;
