import { __decorate } from "tslib";
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { getCCSEngineConcept, getCCSFuelType } from '@/utils';
import { FuelType } from '@/store/modules/vmds/types';
import Modal from '../modal/modal.vue';
import CarConfigurationService from '@/services/CarConfigurationService';
import { isElectricCCS, isHybridCCS } from '@/utils';
let EmissionComponent = class EmissionComponent extends Vue {
    owccWrapper;
    translate;
    currentVehicle;
    currentEngineConcept;
    currentFuelTypes;
    currentEmissions;
    currentEngine;
    currentConsumption;
    vehicleCost;
    get emissionComponentData() {
        return {
            baseData: [
                {
                    label: 'Marke',
                    value: 'Mercedes-Benz',
                },
                {
                    label: 'Handelsbezeichnung',
                    value: `${this.currentVehicle.name}`,
                },
                {
                    label: 'Antriebsart',
                    value: this.engineTypeLabel,
                },
                {
                    label: 'Kraftstoffart',
                    value: this.fuelTypeDisplayLabel,
                },
                {
                    label: 'andere Energieträger',
                    value: this.fuelComplementLabel,
                },
            ],
            energyConsumption: this.energyConsumptionSummaryDetails,
            co2LabelContainer: {
                label: 'CO2-Klasse',
                subLabel: `Auf Grundlage der CO2-Emissionen ${!isHybridCCS(this.engineConcept) ? '(kombiniert)' : ''}`,
                ...this.efficiencyRating,
            },
            furtherConsumptionData: this.furtherConsumptionData,
            costs: [
                {
                    label: `Energiekosten bei ${this.averageKmPerYear}km Jahresfahrleistung`,
                    value: `${this.vehicleCost?.energyCost} €/Jahr`,
                    subvalues: this.costDetailsLabel,
                },
                {
                    label: `Mögliche CO2-Kosten über die nächsten 10 Jahre (${this.averageKmPerYear}km/Jahr)`,
                    value: null,
                    footnotes: this.costsFootnotes,
                    subvalues: [
                        {
                            label: `bei einem angenommenen mittleren durchschnittlichen CO2-Preis von ${this.vehicleCost?.baseData.co2pricesPerTon?.middle}€/t`,
                            value: `${this.vehicleCost?.co2cost?.middle} €`,
                        },
                        {
                            label: `bei einem angenommenen niedrigen durchschnittlichen CO2-Preis von ${this.vehicleCost?.baseData.co2pricesPerTon?.low}€/t`,
                            value: `${this.vehicleCost?.co2cost?.low} €`,
                        },
                        {
                            label: `bei einem angenommenen hohen durchschnittlichen CO2-Preis von ${this.vehicleCost?.baseData.co2pricesPerTon?.high}€/t`,
                            value: `${this.vehicleCost?.co2cost?.high} €`,
                        },
                    ],
                },
                {
                    label: 'Kraftfahrzeugsteuer',
                    value: this.vehicleCost?.carTax?.totalTax
                        ? `${this.vehicleCost?.carTax?.totalTax} €/Jahr`
                        : 'befristet steuerbefreit',
                    footnotes: isElectricCCS(this.engineConcept) ? [3] : [],
                },
            ],
            footnotes: {
                '1': 'Es werden nur die CO2-Emissionen angegeben, die durch den Betrieb des Pkw entstehen. CO2-Emissionen, die durch die Produktion und Bereitstellung des Pkw sowie des Kraftstoffes bzw. der Energieträger entstehen oder vermieden werden, werden bei der Ermittlung der CO2-Emissionen gemäß WLTP nicht berücksichtigt.',
                '2': 'Aufgrund der CO2-Bepreisung sind künftig Erhöhungen der Kraftstoffkosten möglich. Die künftige CO,-Preisentwicklung ist unsicher, daher werden die möglichen CO:-Kosten anhand von drei angenommenen CO2-Preisen für den Zeitraum 2025 bis 2034 berechnet. Die tatsächlichen CO.-Preise können sowohl höher als auch niedriger als in den hier zugrundeliegenden Modellrechnungen ausfallen. Die CO2-Kosten sind beim Tanken mit den Kraftstoffkosten zu bezahlen. Weitere Informationen unter',
                '3': 'Die Steuerbefreiung wird bei erstmaliger Zulassung des Elektrofahrzeugs in der Zeit vom 18.05.2011 bis 31.12.2025 für zehn Jahre ab dem Tag der erstmaligen Zulassung gewährt, längstens jedoch bis zum 31.12.2030.',
                '': 'Die Informationen erfolgen gemäß der Pkw-Energieverbrauchskennzeichnungsverordnung. Die angegebenen Werte wurden nach dem vorgeschriebenen Messverfahren WLTP (Worldwide harmonised Light-duty vehicles Test Procedures) ermittelt. Der Kraftstoffverbrauch und der CO2-Ausstoß eines Pkw sind nicht nur von der effizienten Ausnutzung des Kraftstoffs durch den Pkw, sondern auch vom Fahrstil und anderen nichttechnischen Faktoren abhängig. CO2 ist das für die Erderwärmung hauptsächlich verantwortliche Treibhausgas. Ein Leitfaden über den Kraftstoffverbrauch und die CO2-Emissionen aller in Deutschland angebotenen neuen Pkw-Modelle ist unentgeltlich in elektronischer Form einsehbar an jedem Verkaufsort in Deutschland, an dem neue Pkw ausgestellt oder angeboten werden. Der Leitfaden ist auch hier abrufbar: https://www.mercedes-benz.com/de/external/disclaimer/wltp/.',
            },
        };
    }
    // Final string data formatting for owdc-energy-consumption-labeling component
    get formattedEmissionData() {
        return JSON.stringify(this.emissionComponentData);
    }
    // CO2 Emission Section
    get energyConsumptionSummaryDetails() {
        const consumption = {
            label: `Energieverbrauch (${isHybridCCS(this.engineConcept) ? 'gewichtet, ' : ''}kombiniert)`,
            value: this.energyConsumptionLabel,
            footnotes: isHybridCCS(this.engineConcept) ? [1] : [],
        };
        const emission = {
            label: `CO2-Emissionen (${isHybridCCS(this.engineConcept) ? 'gewichtet, ' : ''}kombiniert)`,
            value: this.combinedCo2emissionLabel,
            footnotes: isHybridCCS(this.engineConcept) ? [1, 2] : [1],
        };
        const autonomy = {
            label: 'Elektrische Reichweite (EAER)',
            value: this.electricEngineRangeLabel,
            footnotes: [1],
        };
        const autonomyUrbanArea = {
            label: 'Elektrische Reichweite innerorts (EAER)',
            value: this.electricEngineRangeUrbanAreaLabel,
            footnotes: [1],
        };
        let energyConsumption = [consumption, emission];
        if (isHybridCCS(this.engineConcept) || isElectricCCS(this.engineConcept)) {
            if (this.electricEngineRange) {
                energyConsumption.push(autonomy);
            }
            if (this.electricEngineRangeUrbanArea) {
                energyConsumption.push(autonomyUrbanArea);
            }
        }
        return energyConsumption;
    }
    getValuePerThresholdAndEngine(threshold, engine) {
        return this.currentConsumption?.wltp?.[threshold]?.[engine]?.individual;
    }
    get furtherConsumptionData() {
        const electricLabel = `Stromverbrauch ${isHybridCCS(this.engineConcept) ? '(rein elektrischer Betrieb, kombiniert)' : 'kombiniert'}`;
        const combustionLabel = `Kraftstoffverbrauch ${isHybridCCS(this.engineConcept) ? '(entladene Batterie, kombiniert)' : 'kombiniert'}`;
        const electricEngineLabel = isHybridCCS(this.engineConcept) ? 'secondaryEngine' : 'primaryEngine';
        const hybridFootnotesReference = { footnotes: isHybridCCS(this.engineConcept) ? [1] : [] };
        const electricTotal = isHybridCCS(this.engineConcept)
            ? `${this.currentConsumption?.wltp?.total?.secondaryEngine?.individual} ${this.currentConsumption?.wltp?.total?.secondaryEngine?.unit}`
            : `${this.combinedElectricConsumption} ${this.combinedElectricConsumptionUnit}`;
        const electricData = {
            label: electricLabel,
            value: `${electricTotal}`,
            ...hybridFootnotesReference,
            subvalues: [
                {
                    label: '- Innenstadt',
                    value: `${this.getValuePerThresholdAndEngine('low', electricEngineLabel)} ${this.combinedElectricConsumptionUnit}`,
                },
                {
                    label: '- Stadtrand',
                    value: `${this.getValuePerThresholdAndEngine('medium', electricEngineLabel)} ${this.combinedElectricConsumptionUnit}`,
                },
                {
                    label: '- Landstraße',
                    value: `${this.getValuePerThresholdAndEngine('high', electricEngineLabel)} ${this.combinedElectricConsumptionUnit}`,
                },
                {
                    label: '- Autobahn',
                    value: `${this.getValuePerThresholdAndEngine('extraHigh', electricEngineLabel)} ${this.combinedElectricConsumptionUnit}`,
                },
            ],
        };
        const combustionData = {
            label: combustionLabel,
            value: `${this.combinedGasConsumption} ${this.combinedGasConsumptionUnit}`,
            ...hybridFootnotesReference,
            subvalues: [
                {
                    label: '- Innenstadt',
                    value: `${this.getValuePerThresholdAndEngine('low', 'primaryEngine')} ${this.combinedGasConsumptionUnit}`,
                },
                {
                    label: '- Stadtrand',
                    value: `${this.getValuePerThresholdAndEngine('medium', 'primaryEngine')} ${this.combinedGasConsumptionUnit}`,
                },
                {
                    label: '- Landstraße',
                    value: `${this.getValuePerThresholdAndEngine('high', 'primaryEngine')} ${this.combinedGasConsumptionUnit}`,
                },
                {
                    label: '- Autobahn',
                    value: `${this.getValuePerThresholdAndEngine('extraHigh', 'primaryEngine')} ${this.combinedGasConsumptionUnit}`,
                },
            ],
        };
        let data = [];
        if (isHybridCCS(this.engineConcept)) {
            data.push(electricData, combustionData);
        }
        else if (isElectricCCS(this.engineConcept)) {
            data.push(electricData);
        }
        else {
            data.push(combustionData);
        }
        return data;
    }
    // CO2 Costs Section
    get averageKmPerYear() {
        // consider making formatting available for all markets once this is a requirement
        return Intl.NumberFormat('de').format(this.vehicleCost?.baseData.averageKilometersPerYear || 15000); // fallsback to 15000 if not available on api.
    }
    get costsFootnotes() {
        if (isHybridCCS(this.engineConcept)) {
            return [3];
        }
        else {
            return [2];
        }
    }
    get costDetailsLabel() {
        const combustionDetails = {
            label: `bei Krafstoffpreis (Jahresdurchschnitt ${this.vehicleCost?.baseData.energyCostBaseDate})`,
            value: `${this.vehicleCost?.baseData.avgFuelPricesPer100km[this.fuelType]} €/l`,
        };
        const electricDetails = {
            label: `bei Strompreis (Jahresdurchschnitt ${this.vehicleCost?.baseData.energyCostBaseDate})`,
            value: `${Math.ceil(this.vehicleCost?.baseData.avgFuelPricesPer100km.ELECTRIC * 100)} ct/kWh`,
        };
        let subvalues = [];
        if (isHybridCCS(this.engineConcept)) {
            subvalues.push(combustionDetails, electricDetails);
        }
        else if (isElectricCCS(this.engineConcept)) {
            subvalues.push(electricDetails);
        }
        else {
            subvalues.push(combustionDetails);
        }
        return subvalues;
    }
    // emission component v2 dynamic props
    get fuelTypeDisplayLabel() {
        switch (this.currentFuelTypes[0]) {
            case FuelType.DIESEL:
                return this.translate('diesel');
            case FuelType.PETROL:
                return this.translate('petrol');
            case FuelType.PETROL_PLUS:
                return this.translate('petrolPlus');
            case FuelType.ELECTRIC:
                return this.translate('electric');
            default:
                return 'entfällt';
        }
    }
    get isHybridOrElectric() {
        return isHybridCCS(this.engineConcept) || isElectricCCS(this.engineConcept);
    }
    get fuelComplementLabel() {
        return this.isHybridOrElectric ? 'Strom' : 'entfällt';
    }
    get combinedElectricConsumption() {
        if (isHybridCCS(this.engineConcept)) {
            return this.currentConsumption?.wltp?.chargeDepleteWeighted?.secondaryEngine?.individual;
        }
        return this.currentConsumption?.wltp?.total?.primaryEngine?.individual;
    }
    get combinedElectricConsumptionUnit() {
        if (isHybridCCS(this.engineConcept)) {
            return this.currentConsumption?.wltp?.chargeDepleteWeighted?.secondaryEngine?.unit;
        }
        return this.currentConsumption?.wltp?.total?.primaryEngine?.unit;
    }
    get energyConsumptionLabel() {
        if (isHybridCCS(this.engineConcept)) {
            return `${this.combinedElectricConsumption} ${this.combinedElectricConsumptionUnit} + ${this.combinedGasConsumption} ${this.combinedGasConsumptionUnit}`;
        }
        return `${this.combinedGasConsumption} ${this.combinedGasConsumptionUnit}`;
    }
    get combinedElectricConsumptionLabel() {
        return `${this.combinedElectricConsumption} ${this.combinedElectricConsumptionUnit}`;
    }
    get combinedGasConsumption() {
        if (isHybridCCS(this.engineConcept)) {
            return this.currentConsumption?.wltp?.weighted?.primaryEngine?.individual;
        }
        return this.currentConsumption?.wltp?.total?.primaryEngine?.individual;
    }
    get combinedGasConsumptionUnit() {
        if (isHybridCCS(this.engineConcept)) {
            return this.currentConsumption?.wltp?.weighted?.primaryEngine?.unit;
        }
        return this.currentConsumption?.wltp?.total?.primaryEngine?.unit;
    }
    get combinedGasConsumptionLabel() {
        return `${this.combinedGasConsumption} ${this.combinedGasConsumptionUnit}`;
    }
    get efficiencyRating() {
        if (isHybridCCS(this.engineConcept)) {
            return {
                energyEfficiencyClass: this.currentEmissions?.wltp?.co2ClassWeighted.individual,
                energyEfficiencyClassSecondary: this.currentEmissions?.wltp?.co2Class.individual,
            };
        }
        else {
            return {
                energyEfficiencyClass: this.currentEmissions?.wltp?.co2Class.individual,
            };
        }
    }
    get combinedCo2emission() {
        return (this.currentEmissions?.wltp?.weighted?.primaryEngine?.individual ||
            this.currentEmissions?.wltp?.total?.primaryEngine?.individual);
    }
    get combinedCo2emissionLabel() {
        return `${this.combinedCo2emission} ${this.currentEmissions?.wltp?.total?.primaryEngine?.unit}`;
    }
    get fuelType() {
        return getCCSFuelType(this.currentFuelTypes[0]);
    }
    get engineConcept() {
        return getCCSEngineConcept(this.currentEngineConcept);
    }
    get engineTypeLabel() {
        if (isHybridCCS(this.engineConcept)) {
            return 'Plug-In-Hybrid';
        }
        else if (isElectricCCS(this.engineConcept)) {
            return 'Elektromotor';
        }
        else {
            return 'Verbrennungsmotor';
        }
    }
    get engineCapacity() {
        return this.currentEngine?.combustionEngine?.capacity?.value;
    }
    get electricEngineRange() {
        if (isHybridCCS(this.engineConcept)) {
            return this.currentEngine?.electricEngine?.wltpRange?.total?.equivalentAll?.individual;
        }
        return this.currentEngine?.electricEngine?.wltpRange?.total?.all?.individual;
    }
    get electricEngineRangeLabel() {
        if (isHybridCCS(this.engineConcept)) {
            return `${this.electricEngineRange} ${this.currentEngine?.electricEngine?.wltpRange?.total?.equivalentAll?.unit}`;
        }
        return `${this.electricEngineRange} ${this.currentEngine?.electricEngine?.wltpRange?.total?.all?.unit}`;
    }
    get electricEngineRangeUrbanArea() {
        if (isHybridCCS(this.engineConcept)) {
            return this.currentEngine?.electricEngine?.wltpRange?.lowMid?.equivalentAll?.individual;
        }
        return this.currentEngine?.electricEngine?.wltpRange?.lowMid?.all?.individual;
    }
    get electricEngineRangeUrbanAreaLabel() {
        if (isHybridCCS(this.engineConcept)) {
            return `${this.electricEngineRangeUrbanArea} ${this.currentEngine?.electricEngine?.wltpRange?.lowMid?.equivalentAll?.unit}`;
        }
        return `${this.electricEngineRangeUrbanArea} ${this.currentEngine?.electricEngine?.wltpRange?.lowMid?.all?.unit}`;
    }
    // Component exclusive methods
    emissionModalIsOpen = false;
    toggleEmissionModal() {
        this.emissionModalIsOpen = !this.emissionModalIsOpen;
    }
    async fetchData() {
        this.vehicleCost = await CarConfigurationService.fetchVehicleCosts(this.combinedElectricConsumption, this.combinedGasConsumption, this.combinedCo2emission, this.engineCapacity, this.fuelType, this.engineConcept);
        const parentEl = this.$refs.owccWrapper;
        const emissionComponents = parentEl.getElementsByTagName('owcc-energy-consumption-labeling');
        Array.from(emissionComponents).forEach((element) => {
            element.setAttribute('label-data', JSON.stringify(this.emissionComponentData));
        });
    }
    onPropsChange() {
        if (this.combinedGasConsumption) {
            this.fetchData();
        }
    }
    mounted() {
        this.fetchData();
    }
};
__decorate([
    Ref('owccWrapper')
], EmissionComponent.prototype, "owccWrapper", void 0);
__decorate([
    Watch('currentVehicle')
], EmissionComponent.prototype, "onPropsChange", null);
EmissionComponent = __decorate([
    Component({
        name: 'EmissionComponent',
        components: { Modal },
        computed: {
            ...mapGetters([
                'translate',
                'currentEngineConcept',
                'currentFuelTypes',
                'currentEmissions',
                'currentConsumption',
                'currentEngine',
                'currentVehicle',
            ]),
        },
        methods: {
        // ...mapActions(MODULES.CAR_CONFIGURATION, ['getVehicleCosts']), ToDo: integrate with store?
        },
    })
], EmissionComponent);
export default EmissionComponent;
