import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import VehicleDetail from '@/components/vehicle-detail/vehicle-detail.vue';
import PlsPriceUi from '@/components/pls-price-ui/pls-price-ui.vue';
import { plsMarkets } from '@/utils';
let VehicleSelectorMotorization = class VehicleSelectorMotorization extends Vue {
    market;
    id;
    name;
    price;
    priceDescription;
    priceReferences;
    priceSuffix;
    fuelType;
    fuelTypeLabel;
    power;
    enginePowerLabel;
    transmission;
    transmissionLabel;
    engineConcept;
    hybridTranslation;
    vehicle;
    onClick() {
        this.$emit('click');
    }
    get shouldShowPls() {
        return plsMarkets.includes(this.market);
    }
};
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "id", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "name", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "price", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "priceDescription", void 0);
__decorate([
    Prop({ default: [] })
], VehicleSelectorMotorization.prototype, "priceReferences", void 0);
__decorate([
    Prop({ default: '' })
], VehicleSelectorMotorization.prototype, "priceSuffix", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "fuelType", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "fuelTypeLabel", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "power", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "enginePowerLabel", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "transmission", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "transmissionLabel", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "engineConcept", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "hybridTranslation", void 0);
__decorate([
    Prop({ required: true })
], VehicleSelectorMotorization.prototype, "vehicle", void 0);
VehicleSelectorMotorization = __decorate([
    Component({
        name: 'VehicleSelectorMotorization',
        components: { VehicleDetail, PlsPriceUi },
        computed: {
            ...mapGetters(['market']),
        },
    })
], VehicleSelectorMotorization);
export default VehicleSelectorMotorization;
