import CarConfigurationService from '@/services/CarConfigurationService';
const state = {
    vehicleCosts: {
        carTax: {
            co2tax: 0,
            engineCapacityTax: 0,
            totalTax: 0,
            currency: '',
        },
        energyCost: 0,
        co2cost: {
            low: 0,
            middle: 0,
            high: 0,
            currency: '',
        },
        baseData: {
            energyCostBaseDate: '',
            co2priceValidityFromDate: '',
            co2priceValidityToDate: '',
            avgFuelPricesPer100km: {
                SUPER: 0,
                SUPERPLUS: 0,
                SUPER_E10: 0,
                DIESEL: 0,
                ELECTRIC: 0,
            },
            averageKilometersPerYear: 0,
            co2pricesPerTon: {
                low: 0,
                middle: 0,
                high: 0,
            },
        },
    },
};
const mutations = {
    SET_VEHICLE_COSTS_DATA: (state, payload) => {
        state.vehicleCosts = payload;
    },
};
const actions = {
    getVehicleCosts: async ({ commit }, params) => {
        const vehicleCosts = await CarConfigurationService.fetchVehicleCosts(params.combinedElectricConsumption, params.combinedGasConsumption, params.combinedCo2Emission, params.engineCapacity, params.fuelType, params.engineConcept);
        commit('SET_VEHICLE_COSTS_DATA', vehicleCosts);
    },
};
export const CarConfigurationModule = {
    state,
    actions,
    mutations,
    getters: {
        vehicleCosts: (state) => state.vehicleCosts,
    },
};
