import { getDescendantProp } from '@/utils';
export const valueForKey = (dataObject, key) => {
    if (!key) {
        return;
    }
    const value = getDescendantProp(key, dataObject);
    return value?.toString();
};
export const getValue = (item, dataObject) => {
    const primaryValue = valueForKey(dataObject, item.valueKey);
    const unit = item.unitKey ? valueForKey(dataObject, item.unitKey) : '';
    const secondaryValue = item.secondaryValueKey && valueForKey(dataObject, item.secondaryValueKey);
    const { separator = '' } = item;
    if (!primaryValue || (secondaryValue && !separator) || primaryValue === '[object Object]') {
        return '-';
    }
    // Only display secondary value if defined and different from primary
    if (secondaryValue && primaryValue !== secondaryValue) {
        return `${primaryValue} ${separator} ${secondaryValue} ${unit}`.trim();
    }
    return `${primaryValue} ${unit}`.trim();
};
export const getPlsValue = (key, dataObject) => {
    const primaryValue = valueForKey(dataObject, key);
    if (!primaryValue || primaryValue === '[object Object]') {
        return 0;
    }
    return parseFloat(primaryValue);
};
