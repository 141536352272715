import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VehicleSelectorSlide = class VehicleSelectorSlide extends Vue {
    title;
};
__decorate([
    Prop()
], VehicleSelectorSlide.prototype, "title", void 0);
VehicleSelectorSlide = __decorate([
    Component({ name: 'VehicleSelectorSlide' })
], VehicleSelectorSlide);
export default VehicleSelectorSlide;
