import { getDefaultBodytype, getDefaultMotorization, singleClassSelectorFirstStep } from './utils';
import { APP_TYPE } from '@/utils';
import { STEPS } from './constants';
const { ONE_CHOOSE_CLASS, TWO_CHOOSE_BODY_TYPE, THREE_CHOOSE_MOTORIZATION } = STEPS;
const vehicleSelectorState = {
    selectedClass: null,
    selectedBodytype: null,
    selectedVehicle: null,
    currentStep: null,
    previousStep: null,
    isVehicleSelectorActive: false,
    steps: [ONE_CHOOSE_CLASS, TWO_CHOOSE_BODY_TYPE, THREE_CHOOSE_MOTORIZATION],
    disableFullscreen: false,
};
const actions = {
    navigate: ({ commit }, step) => {
        commit('SET_CURRENT_STEP', step);
    },
    back: ({ dispatch, state }) => {
        dispatch('navigate', state.previousStep);
    },
    toggleVehicleSelector: ({ commit, dispatch, state, rootGetters }) => {
        const status = !state.isVehicleSelectorActive;
        const step = rootGetters.isModelSelector ? ONE_CHOOSE_CLASS : singleClassSelectorFirstStep;
        commit('TOGGLE_VEHICLE_SELECTOR', status);
        dispatch('navigate', status ? step : null);
    },
    selectClass: ({ commit }, data) => {
        commit('SET_CLASS', data);
    },
    selectBodyType: ({ commit }, bodyType) => {
        commit('SET_BODY_TYPE', bodyType);
    },
    selectVehicle: ({ commit, getters, dispatch }, vehicle) => {
        dispatch('selectVehicle', {
            classId: getters.selectedClass.classId,
            bodyTypeId: getters.selectedBodytype.id,
            typeId: vehicle.typeId,
        }, { root: true });
        commit('SET_VEHICLE', {
            typeId: vehicle.typeId,
            name: vehicle.name,
        });
        dispatch('updateConfigurations', null, { root: true });
    },
    setDefaults: ({ commit, dispatch }, { vehicleClass, bodytypeId, typeId }) => {
        const defaultBodyType = getDefaultBodytype(vehicleClass, bodytypeId);
        const defaultMotorization = getDefaultMotorization(defaultBodyType.vehicles, typeId);
        commit('SET_CLASS', {
            classId: vehicleClass.classId,
            className: vehicleClass.className,
        });
        commit('SET_BODY_TYPE', {
            id: defaultBodyType.id,
            name: defaultBodyType.name,
        });
        commit('SET_VEHICLE', {
            typeId: defaultMotorization.typeId,
            name: defaultMotorization.name,
        });
        dispatch('selectVehicle', {
            classId: vehicleClass.classId,
            bodyTypeId: defaultBodyType.id,
            typeId: defaultMotorization.typeId,
        }, { root: true });
    },
    disableFullscreen({ commit }, disabledFullscreen) {
        commit('SET_VEHICLE_SELECTOR_FULLSCREEN_TYPE', disabledFullscreen);
    },
};
const mutations = {
    SET_CURRENT_STEP: (state, step) => {
        const currentStepIndex = state.steps.findIndex((_step) => _step === step);
        state.previousStep = state.steps[currentStepIndex - 1] || null;
        state.currentStep = step;
    },
    TOGGLE_VEHICLE_SELECTOR: (state, status) => {
        state.isVehicleSelectorActive = status;
    },
    SET_CLASS: (state, vehicleClass) => {
        state.selectedClass = vehicleClass;
    },
    SET_BODY_TYPE: (state, bodyType) => {
        state.selectedBodytype = bodyType;
    },
    SET_VEHICLE: (state, vehicleId) => {
        state.selectedVehicle = vehicleId;
    },
    SET_VEHICLE_SELECTOR_FULLSCREEN_TYPE: (state, disableFullscreen) => {
        state.disableFullscreen = disableFullscreen;
    },
};
const vehicleSelectorGetters = {
    currentStep: ({ currentStep }) => currentStep,
    vehicleSelectorIsVisible: ({ isVehicleSelectorActive }) => {
        return isVehicleSelectorActive;
    },
    /**
     * Determines whether there are steps to navigate back
     *
     * Special cases:
     * 1 - It should return false when calculator is in simpleCalculator mode, and
     * currentStep is the body type selection step, thus preventing it from going to
     * class selection step
     * @returns - A boolean value
     */
    disableBackNavigation: ({ previousStep, currentStep }, getters, rootState, rootGetters) => {
        const isSimpleSelector = rootGetters.appType === APP_TYPE.SIMPLE_CALCULATOR;
        const isSimpleCalculatorFirstStep = currentStep === singleClassSelectorFirstStep;
        return (isSimpleSelector && isSimpleCalculatorFirstStep) || !previousStep;
    },
    selectedClass: (state) => state.selectedClass,
    selectedBodytype: (state) => state.selectedBodytype,
    selectedVehicle: (state) => state.selectedVehicle,
    isFullscreenDisabled: (state) => state.disableFullscreen,
};
/**
 * VehicleSelector Module
 */
export const VehicleSelector = {
    actions,
    mutations,
    namespaced: true,
    state: vehicleSelectorState,
    getters: vehicleSelectorGetters,
};
