import { default as pck } from '../../package.json';
import { getTrackingDispatchers } from '@owc/connection-aem-tracking';
import { logger } from './logger';
import { VMDSModule } from '@/store/modules/vmds/VMDSModule';
import store from '@/store';
import { vehicleDivision } from '@/store/modules/vehicleSelector/utils';
export var SELECTOR_TYPES;
(function (SELECTOR_TYPES) {
    SELECTOR_TYPES["CLASS"] = "class_selector";
    SELECTOR_TYPES["BODY_TYPE"] = "bodytype_selector";
    SELECTOR_TYPES["MOTORISATION"] = "motorisation_selector";
})(SELECTOR_TYPES || (SELECTOR_TYPES = {}));
class TrackingManager {
    /**
     * Class attributes
     */
    EVENT_ID = 'click';
    APPLICATION_ID = 'fcis-widget';
    APPLICATION_PARENT_ID = 'aem';
    ACTION_ID = 'finance_calculator';
    _version;
    /**
     * Constructor
     */
    constructor() {
        this._version = pck.version;
    }
    /**
     * Methods
     */
    /**
     * initialize: Should be fired once the page is first visible.
     */
    initialize() {
        this.publish({
            category: 'impression',
            label: 'success',
            name: 'impression',
        });
    }
    /**
     * update: Fired every time the input fields are updated
     * @param {string} name - Event trigger field's name
     * @param {string} errorType - Error type: errorCode:string
     * @returns {void}
     */
    update(name, errorType) {
        const payload = {
            category: 'feature',
            label: name,
            name: 'feature',
        };
        if (errorType) {
            payload.errorType = errorType;
        }
        this.publish(payload);
    }
    /**
     * selectOption: Fired every time a selection inside of
     * the vehicle selector is made
     * @param {SELECTOR_TYPES} selectorType
     */
    selectOption(selectorType, variant) {
        const payload = {
            category: 'feature',
            label: `${selectorType}${variant ? '.' + variant : ''}`,
            name: 'select_option',
        };
        this.publish(payload);
    }
    /**
     * action: Fired every time a button is clicked
     * @param {string} name - Button's name
     * @param {string} category - Event's category
     * @param {string} action - Event's action
     * @returns {void}
     */
    async action(name, category = 'link', action) {
        await this.publish({
            category,
            label: name,
            ...(action !== undefined ? { action } : {}),
            ...(category === 'link' ? { name: 'link' } : {}),
        });
    }
    /**
     * publish: method responsible to publish events to the EMB
     * service
     * @param data - TrackingManager methods payload
     * @returns {void}
     */
    async publish(data) {
        const { defaultCustomerType, defaultFinancingProduct } = store?.state?.app;
        let state = '';
        if (defaultCustomerType && defaultFinancingProduct) {
            state = `${defaultCustomerType.toLowerCase()}|${defaultFinancingProduct.toLowerCase()}`;
        }
        const trackingData = {
            application: {
                id: this.APPLICATION_ID,
                version: this._version,
                parentApplicationId: this.APPLICATION_PARENT_ID,
            },
            event: this.EVENT_ID,
            eventDetail: {
                action: this.ACTION_ID,
                ...data,
                vehicle: {
                    baumusterId: VMDSModule?.state?.currentVehicle?.baumuster,
                    type: vehicleDivision(VMDSModule?.state?.currentVehicle?.productGroup),
                },
                ...(state
                    ? {
                        state,
                    }
                    : {}),
            },
        };
        console.log('%cNew tracking event:', 'color: green; background: yellow;', { ...trackingData });
        try {
            const eventDispatchers = await getTrackingDispatchers();
            eventDispatchers.pushTrackingEvent(trackingData);
        }
        catch (err) {
            logger.info(err);
        }
    }
}
/**
 * Create a new singleton
 */
export const tracking = new TrackingManager();
