// This should only proceed when variables are available
// This means it will only be executed on localhost
const { VUE_APP_VMDS_DEV = '', VUE_APP_VMDS_INT = '', VUE_APP_VMDS_PROD = '' } = process.env;
// Replace both INT and PROD urls with no extra work
const replaceUrls = (url) => {
    return url.replace(VUE_APP_VMDS_INT, VUE_APP_VMDS_DEV).replace(VUE_APP_VMDS_PROD, VUE_APP_VMDS_DEV);
};
export function setLocalEnvironment(config) {
    if (!VUE_APP_VMDS_DEV) {
        return config;
    }
    const specificModel = replaceUrls(config.vehicleMasterDataSource.specificModel);
    const multipleModels = replaceUrls(config.vehicleMasterDataSource.multipleModels);
    return {
        ...config,
        vehicleMasterDataSource: {
            specificModel,
            multipleModels,
        },
    };
}
