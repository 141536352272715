import { __decorate } from "tslib";
import { mapGetters } from 'vuex';
import { Vue, Component } from 'vue-property-decorator';
import WltpModal from '@/components/wltp-modal/wltp-modal.vue';
let Footnote = class Footnote extends Vue {
    /**
     * State properties
     */
    WLTPModalStatus = false;
    /**
     * Methods
     */
    toggleWLTPModal() {
        this.WLTPModalStatus = !this.WLTPModalStatus;
    }
};
Footnote = __decorate([
    Component({
        name: 'Footnote',
        components: { WltpModal },
        computed: { ...mapGetters(['outputsConfig', 'disclaimers']) },
    })
], Footnote);
export default Footnote;
